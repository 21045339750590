import * as React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useCallback } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import axios from "axios";
import { serverDomain } from "../config";
import { format } from "date-fns";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";

export default function SearchForm({
  setAPIData,
  resetSlices,
  resetWeights,
  setSearchFormData,
  setNumberOfEntities,
  setSPIScore,
  setPresetOptions,
}) {
  const token = localStorage.getItem("token");
  //console.log("tokenfromLS: " + token);

  //set the state for the form fields
  const [level, setLevel] = useState(""); //Used to hold the index of the selected level from the dropdown list in the form
  const [classification, setClassification] = useState(0);
  const [fromDateTime, setFromDateTime] = useState(new Date());
  const [toDateTime, setToDateTime] = useState(new Date());
  const [formula, setFormula] = useState(0);
  const [weight, setWeight] = useState(0);
  const [selectedEntity, setSelectedEntity] = useState({ id: "", name: "" });
  const [entities, setEntities] = useState([]); //Used to hold the list of entities for the dropdown list in the form
  const [historyEntity, setHistoryEntity] = useState(""); //Used to hold the entity from the user history

  const [loading, setLoading] = useState(false);
  const [unauthorized, setUnauthorized] = useState(false);

  //a message on the form to display if there is an error...
  const [errorMessage, setErrorMessage] = useState(null);

  //init arrays for dashboard filters...
  const [levels, setLevels] = useState([]);
  const [classifications, setClassifications] = useState([]);
  const [formulas, setFormulas] = useState([]);
  const [weights, setWeights] = useState([]);

  // initialize the state for the timeout Alert
  const [showTimeoutAlert, setShowTimeoutAlert] = useState(false);

  //Set selectedEntity to historyEntity when entities changes
  useEffect(() => {
    if (entities.length > 0 && historyEntity !== "") {
      let entity = entities.find((entity) => entity.id === historyEntity);
      //only set the SelectedEntity from historyEntity if it exists in the entities array, as the entities list changes based on search values...
      if (entity !== undefined) {
        setSelectedEntity(entity);
        //clear the historyEntity state so the entity only gets set once from history...
        setHistoryEntity("");
      }
      console.log("Selected entity element: ", entity);
      console.log("historyEntity: ", historyEntity);
    }
  }, [entities]);

  //function to get the FormHistory values for the form...
  // Use data.classifications instead of the classifications state
  const getFormHistory = useCallback(async (data) => {
    // Fetch  your FormHistory values here
    const url = new URL(serverDomain + "/php/historySearch.php");

    //set spinner...
    setLoading(true);

    axios
      .get(url, { headers: { Authorization: `Bearer ${token}` } }) //, timeout: 15000
      .then((res) => {
        //clear the spinner...
        setLoading(false);

        //headers
        console.log("From getFormHistory historySearch.php");
        console.log(res);

        //set the form fields with the user settings from the database
        //but only is res.data.user_settings are not null, so we skip
        //this section if it's a new user who has no search history...
        if (res.data.success === 1 && res.data.user_settings !== null) {
          //set the level form field...
          console.log("levelId ", res.data.user_settings.levelId);
          let levelId = parseInt(res.data.user_settings.levelId, 10);
          //subtract 2 from the levelId to get the index for the level dropdown list, as "Level 2" is the first item in th list, which is index 0...
          setLevel(levelId - 2);

          //set the classification index for the calssification dropdown list...
          console.log("class ", res.data.user_settings.classSelected);
          //find the index of the classification in the classifications array...
          let classIndex = data.classifications.findIndex(
            (item) => item.value === res.data.user_settings.classSelected
          );
          console.log("class index: ", classIndex);
          setClassification(classIndex);
          //set the formula engine index for the formula dropdown list...
          console.log("formula ", res.data.user_settings.formulaEngineSelected);
          //find the index of the formula in the formulas array...
          let formulaIndex = data.formulas.findIndex(
            (item) =>
              item.value === res.data.user_settings.formulaEngineSelected
          );
          setFormula(formulaIndex);

          //set the weight index for the weight dropdown list...
          console.log("weight ", res.data.user_settings.weightEngineSelected);
          //find the index of the weight in the weights array...
          let weightIndex = data.weights.findIndex(
            (item) => item.value === res.data.user_settings.weightEngineSelected
          );
          setWeight(weightIndex);

          //set the fromDateTime state...
          console.log("fromDateTime ", res.data.user_settings.dateTimeStartId);
          setFromDateTime(new Date(res.data.user_settings.dateTimeStartId));

          //set the toDateTime state...
          console.log("toDateTime ", res.data.user_settings.dateTimeEndId);
          setToDateTime(new Date(res.data.user_settings.dateTimeEndId));

          //set the historyEntity state which will set the selectedEntity state later after the entities array is filled...
          console.log(
            "setHistoryEntity entity ",
            res.data.user_settings.entity
          );
          setHistoryEntity(res.data.user_settings.entity);
        }
      });
  }, []);

  const getDropdownValues = useCallback(async () => {
    //clear the SPI score state...
    setSPIScore(-1);

    //set spinner...
    setLoading(true);

    try {
      const response = await axios.get(
        serverDomain + "/php/dashboardFilters.php",
        { headers: { Authorization: `Bearer ${token}` } }
      );

      //clear the spinner...
      setLoading(false);

      console.log("dashboardFilters.php response: ", response);
      // Check if the status is 401 and return early. This happens if the auth_check.php is not working correctly. It was caused by the token not being sent. It's fixed now, but could be a good idea to leave this catch block error message, for cases where network issue do occur..
      if (response.data.status === 401) {
        console.error(response.data.message);
        setErrorMessage("There are network issues, please try again later.");
        return;
      }

      const data = response.data;
      setLevels(data.levels);
      setClassifications(data.classifications);
      console.log("classifications: ", classifications);

      setFormulas(data.formulas);
      setWeights(data.weights);
      return data; // Return the data to use in the getFormHistory(data) function so it uses the most recent data, even if the states have not been updated yet.
    } catch (error) {
      console.error("There was an error!", error);
      console.error("response: ", error);
      setErrorMessage("There are network issues, please try again later.");
    }
  }, []);

  //call the getDropdownValues function to get the dropdown values for the form fields
  //and then call getFormHistory when it returns...
  useEffect(() => {
    getDropdownValues().then((data) => {
      if (data) {
        getFormHistory(data);
      }
    });
  }, [getDropdownValues, getFormHistory]);

  const getEntities = useCallback(async () => {
    if (level === "") {
      return;
    }
    const formData = {
      dateTimeStartId: format(fromDateTime, "yyyy-MM-dd HH:mm"),
      dateTimeEndId: format(toDateTime, "yyyy-MM-dd HH:mm"),

      classSelected: classifications[classification].value,
      //get level_id from the levels array, using the level state as the index
      levelId: levels[level].value,

      weightEngineSelected: weights[weight].value,

      formulaEngineSelected: formulas[formula].value,
    };
    console.log("levelId: " + formData.levelId);
    //log dates
    console.log("formatted fromDateTime: " + formData.dateTimeStartId);
    console.log("formatted toDateTime: " + formData.dateTimeEndId);
    const url = new URL(serverDomain + "/php/entities.php");
    appendParams(url, formData);

    //set spinner...
    setLoading(true);

    axios
      .get(url, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        //headers
        console.log("From getEntities entities.php");
        console.log("token", token);
        console.log("url: " + url.toString());
        console.log(res);
        console.log("API Data: " + res.data.dashboardTreeData);
        console.log("state: level = " + level);

        console.log("state: classification index = " + classification);
        console.log(
          "state: classifications[classification].value = " +
            classifications[classification].value
        );

        console.log("state: formula index = " + formula);
        console.log("state: formula value = " + formulas[formula].value);

        console.log("weight (index) set to: " + weight);
        console.log("weight (value) set to: " + weights[weight].value);

        console.log("state: fromDateTime = " + fromDateTime);
        console.log("state: toDateTime = " + toDateTime);

        //clear the spinner...
        setLoading(false);

        //set the entity state with the entity ids (Stock ticker codes) only if the success field==1 and the dashboardTreeData is not empty...
        if (res.data.success === 1 && res.data.dashboardTreeData.length > 0) {
          const entityData = res.data.dashboardTreeData.map((item) => ({
            id: item.entity_id,
            name: item.entity_name.replace(/\|\s\|/g, " "),
          }));
          setEntities(entityData);
          setNumberOfEntities(entityData.length);
          console.log("Entities: ", entityData);
          console.log("Number of entities: ", entityData.length);
        }
      });

    //get list of preset for level and classification
    const presetsUrl = new URL(serverDomain + "/php/presetOptions.php");
    appendParams(presetsUrl, formData);

    //set spinner...
    setLoading(true);

    axios
      .get(presetsUrl, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        //headers
        console.log("From getEntities presetOptions.php");
        console.log("presetOptions.php token", token);
        console.log("presetOptions.php url: " + presetsUrl.toString());
        console.log("presetOptions.php res.data.preset []", res.data.preset);

        //set the presetOptions state in the parent component
        setPresetOptions(res.data.preset);

        //clear the spinner...
        setLoading(false);
      });
    //dateTimeStartId: format(fromDateTime, "yyyy-MM-dd HH:mm"),
  }, [level, classification, classifications, fromDateTime, toDateTime]);

  //Event handlers

  //handle the level change
  //Store the index of the dropdown item selected in the state
  const onChangeLevel = (event, level) => {
    const selectedLevel = event.target.value;
    /* console.log(
      "Level name: " +
        levels[selectedLevel - 2].text +
        " Level ID: " +
        levels[selectedLevel - 2].value
    ); */ //The two is to adjust the values as it is out by two. event.target.value starts at 0, the level starts at 2, something to do with this.
    console.log("Selected level index: " + selectedLevel);
    setLevel(selectedLevel); // Access the 'value' property of the selected item    getEntities();
    //getEntities();
  };

  // Use the useEffect hook to call the getEntities function when the level or classification state changes
  useEffect(() => {
    //clear the selected entity and entities array
    setSelectedEntity({ id: "", name: "" });
    setEntities([]);
    //Do API call to get new list of entities
    getEntities();
  }, [level, classification, getEntities]); // Dependency array

  const onChangeClassification = (event) => {
    setClassification(event.target.value);
    console.log("class set to: " + event.target.value);
  };

  const onChangeFormula = (event) => {
    setFormula(event.target.value);
    console.log("formula (index) set to: " + event.target.value);
    console.log(
      "formula (value) set to: " + formulas[event.target.value].value
    );
  };

  const onChangeWeight = (event) => {
    setWeight(event.target.value);
    console.log("weight (index) set to: " + event.target.value);
    console.log("weight (value) set to: " + weights[event.target.value].value);
  };

  function onChangeEntity(event) {
    const selectedId = event.target.value;
    const selectedEntity = entities.find((entity) => entity.id === selectedId);
    setSelectedEntity(selectedEntity);
    console.log("Selected entity: ", selectedEntity);
  }

  //API calls...

  //send the form data to the grid search API...
  const sendFormData = async () => {
    if (level === "") {
      return;
    }
    const formData = {
      dateTimeStartId: format(fromDateTime, "yyyy-MM-dd HH:mm"),
      dateTimeEndId: format(toDateTime, "yyyy-MM-dd HH:mm"),
      classId: classifications[classification].value,
      //get level_id from the levels array, using the level state as the index
      levelId: levels[level].value,
      feId: formulas[formula].value,
      wfId: weights[weight].value,
      entityId: selectedEntity.id,
    };

    //set dashborad state: searchFormData to formData object...
    setSearchFormData(formData);

    const url = new URL(serverDomain + "/php/grid.php");
    appendParams(url, formData);
    console.log("url: " + url.toString());
    console.log(token);

    //display spinner...
    setLoading(true);

    //clear the SPI score state...
    setSPIScore(-1);

    //API call...
    axios
      .get(url, {
        headers: { Authorization: `Bearer ${token}` },
        timeout: 15000,
      }) //, timeout: 15000
      .then((res) => {
        //headers
        console.log("From sendFormData() grid.php");
        console.log(token);
        //console.log("url: " + url.toString());
        console.log("search response:", res);
        console.log("API Data: ", res.data.data);
        console.log("API Data success: ", res.data.success);
        console.log("API Data to display: ", res.data);

        //clear the spinner...
        setLoading(false);

        //check is the user is unauthorized...
        if (res.data.message === "Unauthorized") {
          setUnauthorized(true);
        } else {
          setUnauthorized(false);
        }

        //set the apiData state only if the data object exists and the success field==1...
        if (
          res.data &&
          res.data.data &&
          res.data.success === 1 &&
          res.data.data !== false
        ) {
          console.log("API returned success: 1 ");
          setAPIData(JSON.stringify(res.data));
          //make all sliceas visible by called resetSlices function from the parent component...
          resetSlices();
          //reset the weights to 1...
          resetWeights();
        } else if (res.data.data == null || res.data.data == false) {
          //if data is null set APIData state to empty string...
          setAPIData("");
          console.log(
            "API returned res.data=false so API Data set to empty string"
          );
        }
      })
      .catch((error) => {
        if (error.code === "ECONNABORTED") {
          // Show the Alert
          setShowTimeoutAlert(true);
          // clear the spinner...
          setLoading(false);
          // Hide the Alert after 5 seconds
          setTimeout(() => {
            // Hide the Alert
            setShowTimeoutAlert(false);
          }, 7000);
        }
        // ... handle other errors ...
      });
  };

  //append the parameters to the URL
  //appends the key-value pairs from the params object as query parameters to the url object
  const appendParams = (url, params) => {
    Object.entries(params).forEach(([k, v]) => url.searchParams.append(k, v));
  };

  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": { m: 1, width: "25ch" },
      }}
      noValidate
      autoComplete="off"
      /* onSubmit={handleSubmit} */
    >
      <div>
        {errorMessage && (
          <div
            style={{
              color: "black",
              backgroundColor: "pink",
              padding: "10px",
              marginBottom: "10px",
            }}
          >
            {errorMessage}
          </div>
        )}

        <TextField
          id="outlined-select-currency"
          select
          label="Choose level"
          value={level}
          onChange={(event) => onChangeLevel(event, level)}
        >
          {levels.map((option, index) => (
            <MenuItem key={index} value={index}>
              {option.text}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          id="classification"
          select
          label="Choose classification"
          value={classification}
          onChange={(event) => onChangeClassification(event)}
        >
          {classifications.map((option, index) => (
            <MenuItem key={index} value={index}>
              {option.text}
            </MenuItem>
          ))}
        </TextField>

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            label="Start Date"
            value={fromDateTime}
            onChange={setFromDateTime}
            TextFieldComponent={TextField}
          />
        </LocalizationProvider>

        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DateTimePicker
            label="End Date"
            value={toDateTime}
            onChange={setToDateTime}
            TextFieldComponent={TextField}
          />
        </LocalizationProvider>

        <TextField
          id="formula"
          select
          label="Choose formula engine"
          value={formula}
          onChange={(event) => onChangeFormula(event)}
        >
          {formulas.map((option, index) => (
            <MenuItem key={index} value={index}>
              {option.text}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          id="weight"
          select
          label="Choose weight engine"
          value={weight}
          onChange={(event) => onChangeWeight(event)}
        >
          {weights.map((option, index) => (
            <MenuItem key={index} value={index}>
              {option.text}
            </MenuItem>
          ))}
        </TextField>

        <TextField
          id="entity"
          select
          label="Choose the entity"
          value={selectedEntity.id}
          onChange={onChangeEntity}
        >
          {entities.map((entity, index) => (
            <MenuItem key={index} value={entity.id}>
              {entity.name}
            </MenuItem>
          ))}
        </TextField>

        {unauthorized && <Alert severity="error">You are logged out</Alert>}
        <Button
          variant="contained"
          type="submit"
          size="medium"
          sx={{ m: 1, width: "222px", height: "56px" }}
          onClick={(event) => {
            event.preventDefault();
            sendFormData();
          }}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : "Search"}
        </Button>
      </div>
      {showTimeoutAlert && (
        <Alert severity="error">
          No data was returned for this search. Please try other values.
        </Alert>
      )}
    </Box>
  );
}
