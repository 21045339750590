// Ciambella Chart JS for React - v6
// Created: 12-10-2023
// Author: Geoff R. Williams
// Copyright The THRIVE Project - https://strive2thrive.earth

// This version filters the alloc data into VisibleAlloc for slices that are visible
// It draws the slices, slicenumbers, and labels by looping forward from first elelement to last element
// of visibleAlloc and visibleColours arrays.
// It contains loadCiambellaChartData() function to load the chart data from a JSON file.
// To solve the bug where MCS aggregate has 'total_score' when the other classifications have 'score',
// by doing a string replace on the API data using: .replace(/total_score/g, 'score');
// it detects if it's doing an aggregate by searching the data for no "allocation" field.
// and then normalise the values by dividing 1 by the value, so they fit inside the doughnut.
// the loadCiambellaChartData() function accepts the sliceIsVisible array as a parameter

//The data object has a strange shape with datasets[] and labels[] as the chart was originally designed
//to work with chart.js library and this is how it needed the data to be.
var ciambellaChartData = {
  datasets: [
    {
      backgroundColor: [],
      data: [],
      alloc: [],
      inner: [],
      outer: [],
      impact: [],
      spi: [],
      spiScore: 0,
    },
  ],
  labels: [],
};

//This tells ESLint that snippetData is a global variable and it should not warn about it being undefined... It gets set in a script tag by HTML snippet code if it is being used
/* global snippetData */

//define canvas dom element ref and ctx 'canvas context' variables as global, they will be assigned in the loadCiambellaChartData() function
var canvas; // = document.getElementById('CiambellaChart');
var ctx; //var ctx = canvas.getContext('2d');

var showSpiLabels = true; // Set to true to show the SPI labels. Set to false to hide the SPI labels.
var showSliceNumbers = false; //Set to true to show the slice numbers. Set to false to hide the slice numbers. Used in testing, set to false for production.

var chartCanvasBackgroundColor = "white"; // Adjust the background colour of the chart canvas

var centerX; // = 200; //canvas.width / 2; // no need to alter this variable
var centerY; // = 200; //canvas.height / 2; // no need to alter this variable

var radiusScaleFactor = 0.55; // Adjust the factor to control the size of the doughnut. This is used below to multiply the radius of the doughnut's circumference so there is padding around the doughnut.
if (!showSpiLabels) {
  radiusScaleFactor = 0.79;
} // Adjusts the factor to control the size of the doughnut if showSpiLabels is false.

var radius; // = Math.min(centerX, centerY)*radiusScaleFactor; // no need to alter this, it's calculated based on the canvas size and the radiusScaleFactor
var holeRadius; // = radius * 0.45; // Adjust the factor to control the size of the hole
var sliceNumberDistanceFromCurcumference; // =(radius*2)-40; // Adjust the distance to control the distance of the label from the circumference of the doughnut's circumference, i.e. the outer circle. Used in drawSliceNumbers() for testing to see the slice number. drawSliceNumbers() is not used in the final version of the chart.

var holeColour = "white"; // Adjust the colour of the hole

var spiLabelFontSize = "10px Arial"; // Adjust the font size and type for text in the SPI labels
var spiLabelDistanceFromDoughnut = 60; // Adjust the distance to control the distance of the label from the circumference of the doughnut's circumference, i.e. the outer circle
var maxNumberOfDecimalPlacesInSpiLabels = 3; // Adjust the number of decimal places to display in the SPI labels
var spiLabelWidth = 55; // Adjust the width of the rectangle that contains the SPI label, it may need to be modified if the number of decimal places in the SPI label is changed
var spiInnerLabel = "black";
var XORLineDrawingMode = false; // Set to true to draw the connecting lines with XOR. Set to false to draw the connecting lines with the default drawing mode.
var linesColour = "#00f"; // Set the colour of the connecting lines
var lineWidth = 2; // Set the width of the connecting lines
var DataDotRadius = 3; // Adjust the radius of the dots
var dotFillColour = "#ff0"; // Set the colour of the dots
var firstDotFillColour = "#0f0"; // Set the colour of the first dot
var lastDotFillColour = "#f00"; // Set the colour of the last dot
var useColoursForStartAndEndDots = true; // Set to true to use the colours above for the first and last dots. Set to false to use the dotFillColour for all dots.

//var SPI = 0; // Initialize SPI - GLOBAL VARIABLE

//var sliceIsVisible=[true, true, true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true];
var sliceIsVisible = new Array(100).fill(true);
//var midpointAngles = []; // Array to store midpoint angles

var spiScoreFontFamily = "arial"; // Adjust the font size and type for the SPI score in the center of the chart
var spiInnerLabelFontFamily = "arial"; // Adjust the font size and type for the SPI inner label in the center of the chart
var URLLabelFontFamily = "arial"; // Adjust the font size and type for the URL label in the center of the chart

var spiScoreFontSize = "18"; // Adjust the font size and type for the SPI score in the center of the chart
var spiInnerLabelFontSize = "10"; // Adjust the font size and type for the SPI inner label in the center of the chart
var URLLabelFontSize = "10"; // Adjust the font size and type for the URL label in the center of the chart

var spiInnerLabelDistanceFromCenter = 0;
if (!showSpiLabels) {
  spiInnerLabelDistanceFromCenter = 10; // Adjust the distance to control the distance of the label from the center of the chart
}

var hoveredSlice; // Initialize hoveredSlice as a global variable
var agg;
//--------------------------------------------------------------

export function paint() {
  // If snippetData exists, use it to override ciambellaChartData
  if (typeof snippetData !== "undefined" && snippetData) {
    ciambellaChartData = snippetData;
  }
  //don't draw if data is empty...
  if (ciambellaChartData.datasets[0].alloc.length === 0) {
    return;
  }
  drawDoughnut();
  if (showSliceNumbers) {
    drawSliceNumbers();
  }
  if (showSpiLabels) {
    drawSpiValuesAndClassIdInLabelsWithSwatch();
  }
  drawInnerText();
  drawSpiDotsAndLines();
}

//functions

export function clearChart() {
  if (ctx === null) {
    return;
  }
  // Set the fill color
  ctx.fillStyle = chartCanvasBackgroundColor;

  // Clear the canvas
  ctx.clearRect(0, 0, canvas.width, canvas.height);

  // Clear all items from the array
  ciambellaChartData.datasets[0].alloc.length = 0;
  //ciambellaChartData.datasets[0].backgroundColor.length = 0;
}

//this version attempts to draw a border around the slice that is being hovered over
function drawDoughnut() {
  if (ciambellaChartData.datasets[0].alloc.length === 0) {
    return;
  }
  // Get the chartBox div
  //var chartBox = document.getElementById('chartBox');
  // Get the width of the chartBox div
  //var width = chartBox.offsetWidth;
  // Log the width to the console
  //console.log('Width of chartBox: ' + width + 'px');

  // Draw slices AntiClockWise
  var sliceStartAngle = 0; // = Math.PI / 2 - Math.PI / 2; // Start from the three o'clock position
  hoveredSlice = -1;

  //console.log('sliceStartAngle:', sliceStartAngle);
  // Filter visibleAlloc and visibleColours for visible slices
  var visibleAlloc = ciambellaChartData.datasets[0].alloc.filter(
    (_, i) => sliceIsVisible[i]
  );
  var visibleColours = ciambellaChartData.datasets[0].backgroundColor.filter(
    (_, i) => sliceIsVisible[i]
  );

  // Recalculate totalAlloc for visible slices
  var totalAlloc = visibleAlloc.reduce((acc, val) => acc + val, 0);

  // Add event listener to track mouse movements
  canvas.addEventListener("mousemove", function (event) {
    if (ciambellaChartData.datasets[0].alloc.length === 0) {
      return;
    }
    // Get mouse coordinates relative to the canvas
    var mouseX =
      ((event.clientX - canvas.getBoundingClientRect().left) * canvas.width) /
      canvas.clientWidth;
    var mouseY =
      ((event.clientY - canvas.getBoundingClientRect().top) * canvas.height) /
      canvas.clientHeight;

    //sliceStartAngle=0;
    //var slicesliceStartAngle = 0;

    sliceStartAngle = 0; //sliceStartAngle; // Initialize sliceStartAngle to sliceStartAngle
    //console.log('sliceStartAngle:', sliceStartAngle);
    hoveredSlice = -1; // Set hoveredSlice to -1 initially
    for (let i = 0; i < visibleAlloc.length; i++) {
      var sliceAngle = (visibleAlloc[i] / totalAlloc) * 360;
      var sliceEndAngle = sliceStartAngle - sliceAngle; // * 2 * Math.PI;
      // Check if mouse coordinates are within the bounds of the current slice
      var hoveredSliceIndex = isMouseOverSlice(
        mouseX,
        mouseY,
        centerX,
        centerY,
        radius,
        sliceStartAngle,
        sliceEndAngle,
        i
      );
      if (hoveredSliceIndex !== -1) {
        console.log("Mouse over slice:", hoveredSliceIndex);
        hoveredSlice = hoveredSliceIndex;
      }

      sliceStartAngle = sliceEndAngle; // Update sliceStartAngle in the anticlockwise direction
    }
    //draw the chart, labels, dots and lines, and inner text...
    //paint();

    DrawTheChart();
    if (showSliceNumbers) {
      drawSliceNumbers();
    }
    if (showSpiLabels) {
      drawSpiValuesAndClassIdInLabelsWithSwatch();
    }
    drawInnerText();
    drawSpiDotsAndLines();
  });

  function DrawTheChart() {
    if (ciambellaChartData.datasets[0].alloc.length === 0) {
      return;
    }
    //  and draw the slices by Looping through filtered slices in anticlockwise direction
    var DoughnutStartAngle = 0; // = Math.PI / 2 - Math.PI / 2; // Start from the three o'clock position
    //draw big white rectangle to clear the dounut from the canvas before you draw it, to clear the slice selection border

    //ctx.clearRect(0, 0, canvas.width, canvas.height);
    // Set the fill color
    ctx.fillStyle = chartCanvasBackgroundColor;
    // Fill the entire canvas with the specified color
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    for (var i = 0; i < visibleAlloc.length; i++) {
      var sliceAngle = (visibleAlloc[i] / totalAlloc) * 2 * Math.PI;

      // Save the state to avoid interfering with the slice drawing
      ctx.save();
      /* 
    // Set the properties for the shadow
    ctx.shadowColor = 'rgba(0, 0, 0, 0.5)';
    ctx.shadowBlur = 20;
    ctx.shadowOffsetX = 10;
    ctx.shadowOffsetY = 10;
      */
      // Draw the slices in anticlockwise direction
      ctx.beginPath();
      ctx.arc(
        centerX,
        centerY,
        radius,
        DoughnutStartAngle,
        DoughnutStartAngle - sliceAngle,
        true
      );
      ctx.lineTo(centerX, centerY);

      //draw white border around slices...
      ctx.closePath(); // Close the path

      // Set the border color to white
      ctx.strokeStyle = "white";

      // Set the border width (optional)
      ctx.lineWidth = 2;

      // Draw the border
      ctx.stroke();

      //console.log('hoveredSlice:', hoveredSlice, 'i:', i);
      // Change the fill style to a darker colour when the slice is being hovered over
      if (i === hoveredSlice) {
        ctx.fillStyle = darkenColor(visibleColours[i]);
        console.log(darkenColor(visibleColours[i]));
      } else {
        ctx.fillStyle = visibleColours[i];
      }
      //ctx.fillStyle = visibleColours[i];
      ctx.fill();

      //put border around slice if it's being hovered over
      // Draw a black border around the hovered slice
      if (i === hoveredSlice) {
        //ctx.strokeStyle = 'black';
        //ctx.lineWidth = 4;
        //ctx.stroke();
        //ctx.lineTo(centerX, centerY); // Connect the end point of the arc to the center of the circle
      }

      // Restore the state to clear the path and any transformations
      ctx.restore();
      DoughnutStartAngle -= sliceAngle; // Update startAngle in the anticlockwise direction
    }

    // Draw a white circle in the center
    ctx.fillStyle = holeColour;
    ctx.beginPath();
    ctx.arc(centerX, centerY, holeRadius, 0, 2 * Math.PI);
    ctx.fill();
  }

  DrawTheChart();

  function isMouseOverSlice(
    mouseX,
    mouseY,
    centerX,
    centerY,
    radius,
    sliceStartAngle,
    sliceEndAngle,
    sliceNum
  ) {
    // Calculate the distance from the center of the circle to the mouse coordinates
    var distance = Math.sqrt(
      Math.pow(Math.abs(mouseX - centerX), 2) +
        Math.pow(Math.abs(mouseY - centerY), 2)
    );
    // Check if the distance is within the radius
    //console.log('distance:', distance, 'radius:', radius);
    if (distance <= radius && distance >= holeRadius) {
      //console.log('IN RANGE', "slicenum:", sliceNum);
      // Calculate the angle between the slice center and the mouse position
      var angle = Math.atan2(mouseY - centerY, mouseX - centerX); //*180/Math.PI;

      // Normalize angle to be between 0 and 2 * Math.PI, subtract it from 360 (then muliply by 180/PI)
      angle = 360 - (((angle + 2 * Math.PI) % (2 * Math.PI)) * 180) / Math.PI;
      sliceStartAngle = Math.abs(sliceStartAngle);
      sliceEndAngle = Math.abs(sliceEndAngle);
      //console.log('mouseangle:', angle, 'sliceStartAngle:', sliceStartAngle, 'sliceEndAngle:', sliceEndAngle);

      // Check if the angle is within the bounds of the current slice
      if (angle >= sliceStartAngle && angle <= sliceEndAngle) {
        //console.log('Mouse over slice:', sliceNum);
        // You can perform additional actions here based on the hovered slice
        return sliceNum;
      }
    }

    return -1;
  }

  function darkenColor(color) {
    // Convert the color to RGB format
    var rgb;
    if (color.substring(0, 1) === "#") {
      // Convert 3-character hex code to 6-character hex code
      if (color.length === 4) {
        color =
          "#" +
          color.substring(1, 2) +
          color.substring(1, 2) +
          color.substring(2, 3) +
          color.substring(2, 3) +
          color.substring(3, 4) +
          color.substring(3, 4);
      }
      // Convert hex code to RGB format
      rgb =
        "rgb(" +
        parseInt(color.substring(1, 3), 16) +
        ", " +
        parseInt(color.substring(3, 5), 16) +
        ", " +
        parseInt(color.substring(5, 7), 16) +
        ")";
    } else if (color.substring(0, 4) === "rgba") {
      // Convert RGBA format to RGB format
      rgb = "rgb(" + color.substring(5, color.lastIndexOf(",")) + ")";
    } else {
      rgb = color;
    }

    // Calculate the new RGB values by subtracting 20 from each value
    var newRgb = rgb
      .substring(4, rgb.length - 1)
      .replace(/ /g, "")
      .split(",")
      .map(function (value) {
        return Math.max(parseInt(value) - 60, 0);
      });

    // Convert the new RGB values back to CSS color format
    var newColor = "rgb(" + newRgb.join(",") + ")";

    return newColor;
  }
}

function drawSliceNumbers() {
  //draw slice numbers AntiClockWise

  var startAngle = Math.PI / 2 - Math.PI / 2; // Start from the three o'clock position

  // Filter visibleAlloc and visibleColours for visible slices
  var visibleAlloc = ciambellaChartData.datasets[0].alloc.filter(
    (_, i) => sliceIsVisible[i]
  );
  var visibleColours = ciambellaChartData.datasets[0].backgroundColor.filter(
    (_, i) => sliceIsVisible[i]
  );

  // Recalculate totalAlloc for visible slices
  var totalAlloc = visibleAlloc.reduce((acc, val) => acc + val, 0);

  // Loop through filtered slices in anticlockwise direction
  for (var i = 0; i < visibleAlloc.length; i++) {
    var sliceAngle = (visibleAlloc[i] / totalAlloc) * 2 * Math.PI;

    // Calculate the luminance of the background color
    var backgroundColor = hexToRgb(visibleColours[i]);
    var luminance =
      (0.299 * backgroundColor.r +
        0.587 * backgroundColor.g +
        0.114 * backgroundColor.b) /
      255;

    // Decide the text color based on the luminance
    var textColor = luminance > 0.5 ? "black" : "white";

    // Calculate the position for the text using Math.sin and Math.cos
    var textX =
      centerX +
      (sliceNumberDistanceFromCurcumference / 2) *
        Math.cos(startAngle - sliceAngle / 2);
    var textY =
      centerY +
      (sliceNumberDistanceFromCurcumference / 2) *
        Math.sin(startAngle - sliceAngle / 2);

    // Draw the impact value in the center of each slice
    ctx.fillStyle = textColor;
    ctx.font = spiLabelFontSize; // Set the font size and type
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.fillText(i + 1, textX, textY);

    // Decrement startAngle for the next iteration
    startAngle -= sliceAngle;
  }
}

function drawSpiValuesAndClassIdInLabelsWithSwatch() {
  var startAngle = Math.PI / 2 - Math.PI / 2; // Start from the three o'clock position

  // Recalculate totalAlloc for visible slices
  var visibleAlloc = ciambellaChartData.datasets[0].alloc.filter(
    (_, i) => sliceIsVisible[i]
  );
  var totalAlloc = visibleAlloc.reduce((acc, val) => acc + val, 0);

  // Filter .spi[] for visible slices
  var visibleSpi = ciambellaChartData.datasets[0].spi.filter(
    (_, i) => sliceIsVisible[i]
  );
  // Filter .backgroundColor[] for visible slices
  var visibleColours = ciambellaChartData.datasets[0].backgroundColor.filter(
    (_, i) => sliceIsVisible[i]
  );
  // Filter .labels[] for visible slices
  var visibleLabels = ciambellaChartData.labels
    .filter((_, i) => sliceIsVisible[i])
    .map((label) => label.split(" -")[0]);

  var hoveredSliceRect = null; // Initialize the hovered slice rectangle to null

  //var textX = "";
  //var textY = "";

  for (var i = 0; i < visibleAlloc.length; i++) {
    var sliceAngle = (visibleAlloc[i] / totalAlloc) * 2 * Math.PI;

    // Draw the rectangles with impact values
    var rectWidth = spiLabelWidth; // Adjust the width as needed
    var rectHeight = 40; // Increased height to accommodate two lines
    var rectX =
      centerX +
      (radius + spiLabelDistanceFromDoughnut) *
        Math.cos(startAngle - sliceAngle / 2) -
      rectWidth / 2;
    var rectY =
      centerY +
      (radius + spiLabelDistanceFromDoughnut) *
        Math.sin(startAngle - sliceAngle / 2) -
      rectHeight / 2;

    // Calculate luminance for the swatch
    var backgroundColor = hexToRgb(visibleColours[i]);
    var luminance =
      (0.299 * backgroundColor.r +
        0.587 * backgroundColor.g +
        0.114 * backgroundColor.b) /
      255;

    // Decide the text color based on the luminance
    var textColor = luminance > 0.5 ? "black" : "white";

    // Draw the white rectangle with a black border
    ctx.fillStyle = "white";
    ctx.strokeStyle = "black";

    if (hoveredSlice !== i) {
      ctx.fillStyle = "white";
      ctx.strokeStyle = "black";
    } else {
      // Save the rectangle for the hovered slice
      hoveredSliceRect = {
        x: rectX,
        y: rectY,
        width: rectWidth,
        height: rectHeight,
        colour: visibleColours[i],
        spi: visibleSpi[i].toFixed(maxNumberOfDecimalPlacesInSpiLabels),
        label: visibleLabels[i],
      };
    }

    ctx.lineWidth = 1;
    ctx.fillRect(rectX, rectY, rectWidth, rectHeight);
    ctx.strokeRect(rectX, rectY, rectWidth, rectHeight);

    // Calculate the position for the swatch
    var swatchHeight = 20;
    var swatchX = rectX;
    var swatchY = rectY;

    // Draw the swatch
    ctx.fillStyle = visibleColours[i];
    ctx.fillRect(swatchX, swatchY, rectWidth, swatchHeight);

    // Draw the classId inside the swatch, centered
    var classIdX = swatchX + rectWidth / 2;
    var classIdY = swatchY + swatchHeight / 2;
    ctx.fillStyle = textColor; // Use the calculated text color
    ctx.font = "10px Arial";
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.fillText(visibleLabels[i], classIdX, classIdY);

    // Draw the SPI value inside the rectangle, below the swatch
    var spiX = rectX + rectWidth / 2;
    var spiY = swatchY + swatchHeight + (rectHeight - swatchHeight) / 2;

    ctx.fillStyle = "black";
    ctx.font = "10px Arial";
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.fillText(
      visibleSpi[i].toFixed(maxNumberOfDecimalPlacesInSpiLabels),
      spiX,
      spiY
    );

    startAngle -= sliceAngle; // Decrement startAngle for anticlockwise direction
  }

  // Draw the label for the hovered slice after all labels have been drawn
  if (hoveredSliceRect !== null) {
    ctx.fillStyle = hoveredSliceRect.colour;
    ctx.strokeStyle = "black";
    ctx.lineWidth = 2;
    ctx.fillRect(
      hoveredSliceRect.x,
      hoveredSliceRect.y,
      hoveredSliceRect.width,
      hoveredSliceRect.height
    );
    ctx.strokeRect(
      hoveredSliceRect.x,
      hoveredSliceRect.y,
      hoveredSliceRect.width,
      hoveredSliceRect.height
    );

    // Calculate luminance for the hovered slice
    var hoveredBackgroundColor = hexToRgb(hoveredSliceRect.colour);
    var hoveredLuminance =
      (0.299 * hoveredBackgroundColor.r +
        0.587 * hoveredBackgroundColor.g +
        0.114 * hoveredBackgroundColor.b) /
      255;

    // Decide the text color based on the luminance
    var hoveredTextColor = hoveredLuminance > 0.5 ? "black" : "white";

    // Draw the classId inside the swatch for hovered slice
    classIdX = hoveredSliceRect.x + hoveredSliceRect.width / 2;
    classIdY = hoveredSliceRect.y + swatchHeight / 2;
    ctx.fillStyle = hoveredTextColor; // Use the calculated text color for hover
    ctx.font = "10px Arial";
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.fillText(hoveredSliceRect.label, classIdX, classIdY);

    // Draw the SPI value inside the rectangle for hovered slice
    spiX = hoveredSliceRect.x + hoveredSliceRect.width / 2;
    spiY =
      hoveredSliceRect.y +
      swatchHeight +
      (hoveredSliceRect.height - swatchHeight) / 2;

    ctx.fillStyle = hoveredTextColor; // Use the calculated text color for hover
    ctx.font = "10px Arial";
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.fillText(hoveredSliceRect.spi, spiX, spiY);
  }
}

function drawSpiDotsAndLines() {
  //---------------- Draw datapoints and connecting lines ---------------
  // Draw impact values in labels with swatch AntiClockWise
  var startAngle = Math.PI / 2 - Math.PI / 2; // Start from the three o'clock position

  // Recalculate totalAlloc for visible slices
  var visibleAlloc = ciambellaChartData.datasets[0].alloc.filter(
    (_, i) => sliceIsVisible[i]
  );
  var totalAlloc = visibleAlloc.reduce((acc, val) => acc + val, 0);

  // Filter .spi[] for visible slices
  var visibleSpi = ciambellaChartData.datasets[0].spi.filter(
    (_, i) => sliceIsVisible[i]
  );

  // Initialize px and py arrays
  var px = [];
  var py = [];

  // Draw lines with XOR...
  if (XORLineDrawingMode === true) {
    ctx.globalCompositeOperation = "xor";
  }

  ctx.lineWidth = lineWidth;
  ctx.strokeStyle = linesColour;
  ctx.lineJoin = "round";
  ctx.beginPath();

  // Start at the first point (i=0)...
  for (let i = 0; i < visibleAlloc.length; i++) {
    var spi = visibleSpi[i];

    // Correct for out of range above values...
    if (spi >= 1.4) {
      spi = 1.4;
    }

    // Correct for out of range below values...
    if (spi <= -0.3) {
      spi = -0.3;
    }

    // Calculate dot position based on slice angle and SPI score
    //var angle = (ciambellaChartData.datasets[0].alloc[i] / totalAlloc) * 2 * Math.PI;
    var sliceAngle = (visibleAlloc[i] / totalAlloc) * 2 * Math.PI;

    var currentRadius = holeRadius + spi * (radius - holeRadius);

    // Calculate position based on the updated radius
    var dotX = centerX + currentRadius * Math.cos(startAngle - sliceAngle / 2);
    var dotY = centerY + currentRadius * Math.sin(startAngle - sliceAngle / 2);

    // Store the positions in the arrays
    px[i] = dotX;
    py[i] = dotY;

    if (i === 0) {
      // Move pen to the first point location...
      ctx.moveTo(dotX, dotY);
    } else {
      ctx.lineTo(dotX, dotY);
    }
    startAngle -= sliceAngle; // Decrement startAngle for anticlockwise direction
  }

  ctx.stroke();

  //---------------- Join last datapoint to the first ----------------
  // Move pen to the first point location...
  ctx.beginPath();
  ctx.moveTo(px[0], py[0]); // This should be the index of the first visible dot
  ctx.strokeStyle = linesColour;
  ctx.lineTo(px[visibleAlloc.length - 1], py[visibleAlloc.length - 1]); // This should be the index of the last visible dot
  ctx.stroke();

  // Reset the drawing mode to default...
  ctx.globalCompositeOperation = "source-over";

  // Stop the radius from going negative when the chart is shrunk, when the screen is too narrow...
  if (DataDotRadius < 0) {
    DataDotRadius = 0;
  }

  //---------------- Draw Datapoints ----------------
  ctx.lineWidth = 1;

  for (let i = 0; i < visibleAlloc.length; i++) {
    ctx.beginPath();
    ctx.moveTo(px[i] + DataDotRadius, py[i]);
    ctx.strokeStyle = "black";
    ctx.arc(px[i], py[i], DataDotRadius, 0, Math.PI * 2, true);
    ctx.stroke();
    ctx.fillStyle = dotFillColour;
    ctx.fill();
  }

  if (useColoursForStartAndEndDots === true) {
    //---------------- Make first datapoint green ----------------
    ctx.beginPath();
    ctx.moveTo(px[0] + DataDotRadius, py[0]);
    ctx.arc(px[0], py[0], DataDotRadius, 0, Math.PI * 2, true);
    ctx.fillStyle = firstDotFillColour;
    ctx.fill();

    //---------------- Make last datapoint red ----------------
    ctx.beginPath();
    ctx.moveTo(
      px[visibleAlloc.length - 1] + DataDotRadius,
      py[visibleAlloc.length - 1]
    );
    ctx.arc(
      px[visibleAlloc.length - 1],
      py[visibleAlloc.length - 1],
      DataDotRadius,
      0,
      Math.PI * 2,
      true
    );
    ctx.fillStyle = lastDotFillColour;
    ctx.stroke();
    ctx.fill();
  }
  /* 
  var SPITotal = 0;

  // Calculate average SPI for all slices selected...
  for (var a = 0; a < visibleAlloc.length; a += 1) {
    spi = visibleSpi[a];

    if (spi === null || isNaN(spi)) {
      spi = 0;
    }

    SPITotal += spi;
  }

  SPI = SPITotal / visibleAlloc.length;
   */
  //console.log("SPI: " + SPI);
  //console.log("numOfSelectedSlices: " + visibleAlloc.length);
}

// Hex to RGB conversion function
function hexToRgb(hex) {
  // Remove the hash if it's present
  hex = hex.replace(/^#/, "");

  // Handle 3-character hex colors
  if (hex.length === 3) {
    hex = hex
      .split("")
      .map(function (c) {
        return c + c;
      })
      .join("");
  }

  var bigint = parseInt(hex, 16);
  return {
    r: (bigint >> 16) & 255,
    g: (bigint >> 8) & 255,
    b: bigint & 255,
  };
}

function drawInnerText() {
  //---------------- Draw SPI figure in center of the chart ----------------

  console.log("SPI Score raw ", ciambellaChartData.datasets[0].spiScore);

  //don't draw the inner text if SPi is Not A Number...
  if (isNaN(ciambellaChartData.datasets[0].spiScore)) {
    //return;
  }

  if (ciambellaChartData.datasets[0].alloc.length === 0) {
    return;
  }

  //average of visible spi values...
  //var spiScore=parseFloat(SPI).toFixed(3);

  ctx.font = "normal " + spiScoreFontSize + "px " + spiScoreFontFamily;
  ctx.fillStyle = "#25BDE2";
  ctx.strokeStyle = "#109DC2";
  ctx.strokeWidth = "0px";
  ctx.textAlign = "center";
  ctx.textBaseline = "middle";

  //only draw the spiScore if it's 'allocation' field is present, i.e. agg===false, set in adapter,
  //or not -1 (it is set to -1 when a search is performed, it will be -1 while we are waiting for rank_score API to return the SPI score)...
  // I tried adding:  && !isNaN(ciambellaChartData.datasets[0].spiScore) to fix the dashboard resize bug, but it will just cause the SPI number to no be drawn. Not a fix.
  if (agg === false && ciambellaChartData.datasets[0].spiScore !== -1) {
    //use the spiScore from the data...
    var spiScore = parseFloat(ciambellaChartData.datasets[0].spiScore).toFixed(
      3
    );

    ctx.fillText(spiScore, centerX, centerY);
    //no outline: ctx.strokeText(spiScore, centerX, centerY);
  }
  //draw label...
  ctx.font =
    "normal " + spiInnerLabelFontSize + "px " + spiInnerLabelFontFamily;
  ctx.fillStyle = spiInnerLabel;

  ctx.fillText(
    "Sustainability",
    centerX,
    centerY - 36 - spiInnerLabelDistanceFromCenter
  );
  ctx.fillText(
    "Performance",
    centerX,
    centerY - 27 - spiInnerLabelDistanceFromCenter
  );
  ctx.fillText(
    "Index",
    centerX,
    centerY - 18 - spiInnerLabelDistanceFromCenter
  );

  //draw url...
  ctx.font = "normal " + URLLabelFontSize + "px " + URLLabelFontFamily;
  ctx.fillStyle = spiInnerLabel;

  ctx.fillText(
    "strive2thrive.earth",
    centerX,
    centerY + 21 + spiInnerLabelDistanceFromCenter
  );
}

//---------------------------------------------------------------------------------------------
//function to initialise the chart context and canvas ref
export function initChart(localCtx, localCanvasRef) {
  //set the global ctx variable to the localCtx variable passed in...
  ctx = localCtx;

  //set the global canvas variable to the localCanvasRef variable passed in if it is not null...
  if (localCanvasRef) {
    canvas = localCanvasRef.current;

    //set global centerX and centerY variables to the canvas width and height divided by 2...
    centerX = canvas.width / 2; // no need to alter this variable
    centerY = canvas.height / 2; // no need to alter this variable

    //set radius, holeRadius, and sliceNumberDistanceFromCurcumference variables...
    radius = Math.min(centerX, centerY) * radiusScaleFactor; // no need to alter this, it's calculated based on the canvas size and the radiusScaleFactor
    holeRadius = radius * 0.45; // Adjust the factor to control the size of the hole
    sliceNumberDistanceFromCurcumference = radius * 2 - 40; // Adjust the distance to control the distance of the label from the circumference of the doughnut's circumference, i.e. the outer circle. Used in drawSliceNumbers() for testing to see the slice number. drawSliceNumbers() is not used in the final version of the chart.
  }
}

//---------------------------------------------------------------------------------------------
//Adapter function to get the data from the JSON API response and convert it to the
//data format required by the chart.js library. And to use the different field names that
//are found in the API responses...
//Also load in weight[] to recalc the spi values to display in the chart when weights are
//edited in the Grid component...
export function loadCiambellaChartData(
  APIresponseObj,
  slices,
  weight,
  SPIScore
) {
  // Check if APIresponseObj is empty
  if (Object.keys(APIresponseObj).length === 0) {
    console.log("APIresponseObj is empty");
    return;
  }

  // Check if slices is empty
  if (slices.length === 0) {
    console.log("slices array is empty");
    return;
  }

  //set the global sliceIsVisible array to the slices array passed in...
  sliceIsVisible = slices;

  //log the sliceIsVisible array...
  console.log("sliceIsVisible: " + sliceIsVisible);

  //set a flag to indicate that the data has a field called "total_score", meaning it was aggregate data
  //so we can normalize this data after it's extracted into the score var later...
  agg = false; //total_score
  //if (!APIresponseObj.includes('"allocation"')) {
  if (
    typeof APIresponseObj === "string" &&
    !APIresponseObj.includes("allocation")
  ) {
    agg = true;
    console.log(
      "aggregate data detected! APIresponseObj does not include 'allocation'"
    );
  }

  //change total_score to score, because MCS data uses "total_score" and the other classifications use "score"
  //THIS NEEDS TO BE FIXED IN THE API
  if (typeof APIresponseObj === "string") {
    APIresponseObj = APIresponseObj.replace(/total_score/g, "score");
  }

  //change legend_id to topic_name, because MCS data uses "legend_id" and the other classifications use "topic_name"
  //THIS NEEDS TO BE FIXED IN THE API
  if (typeof APIresponseObj === "string") {
    APIresponseObj = APIresponseObj.replace(/legend_id/g, "topic_name");
  }

  //set global variable to the API response object...
  //responseObj=APIresponseObj;
  console.log("convertResponse button clicked, adapter run from within plugin");

  //var responseObj=$("#apiResponse").val();

  //console.log("response: "+respose);

  //Get array of row ojects...
  try {
    var objArray = JSON.parse(APIresponseObj);
  } catch (error) {
    console.log("Invalid JSON: " + error);
    return; // Exit the function
  }

  //console.log("objArray before: " + JSON.stringify(objArray));

  //console.log("objArrayafter: "+JSON.stringify(objArray));

  var allocation = [];
  var inner = [];
  var outer = [];
  var impact = [];
  var score = [];
  var total_score = [];
  var topicName = [];
  var topicNumber = [];
  let topicNumTemp = 0; // Initialize with default value

  var rows = objArray.data;

  //clear the data object...
  ciambellaChartData.datasets[0].data = [];
  ciambellaChartData.datasets[0].alloc = [];
  ciambellaChartData.datasets[0].inner = [];
  ciambellaChartData.datasets[0].outer = [];
  ciambellaChartData.datasets[0].impact = [];
  ciambellaChartData.datasets[0].spi = [];
  ciambellaChartData.datasets[0].total_score = [];
  ciambellaChartData.labels = [];
  ciambellaChartData.datasets[0].entity_id = "";
  ciambellaChartData.datasets[0].entity_name = "";
  ciambellaChartData.datasets[0].backgroundColor = [];

  let i = 0;
  for (var c in rows) {
    allocation[i] = parseFloat(rows[i].allocation);
    inner[i] = parseFloat(rows[i].inner_limit);
    outer[i] = parseFloat(rows[i].outer_limit);
    impact[i] = parseFloat(rows[i].impact);

    //set the score[] to API response "score" field, if it exists...
    try {
      score[i] = parseFloat(rows[i].score);
    } catch (err) {
      score[i] = 0;
    }

    //set the total_score[] to API response "total_score" field, if it exists...
    try {
      total_score[i] = parseFloat(rows[i].total_score);
      console.log("total_score[i]: " + total_score[i]);
    } catch (err) {
      total_score[i] = 0;
    }

    console.log(
      "after finally total_score[i]: " +
        ciambellaChartData.datasets[0].total_score[i]
    );

    //set topicName[] to API response "topic_name" field, if it exists, else set it to "legend_id"...
    try {
      topicName[i] = rows[i].topic_name;
    } catch (err) {
      topicName[i] = rows[i].legend_id;
    }

    topicNumTemp = rows[i].class_path_id;

    //split string into array...
    topicNumTemp = topicNumTemp.split("|");

    //show how many elements were split...
    console.log("class_path_id has: " + topicNumTemp.length + " elements");

    // ***** output topicNumTemp array (all the elements that were split out from rows[i].class_path_id)...
    for (let j = 0; j < topicNumTemp.length; j++) {
      console.log("topicNumTemp[" + j + "]: " + topicNumTemp[j]);
    }

    //console.log("topicNumTemp[topicNumTemp.length-1]: "+topicNumTemp[topicNumTemp.length-1]);

    //set topic number as last element in array (the last thing in the string after the last pipe char | )...
    topicNumber[i] = topicNumTemp[topicNumTemp.length - 1];

    //console.log("topicNumTemp: "+topicNumTemp);

    //Set the classification name to the first element in the array ...
    ciambellaChartData.datasets[0].class_name = topicNumTemp[0];

    console.log("allocation: " + allocation[i]);
    console.log("inner: " + inner[i]);
    console.log("outer: " + outer[i]);
    console.log("impact: " + impact[i]);
    console.log("SPi: " + score[i]);
    console.log("topicName: " + topicName[i]);
    console.log("topicNumber: " + topicNumber[i]);

    //place the extracted data into the ciambellaChartData object...
    ciambellaChartData.datasets[0].data[i] = allocation[i];
    ciambellaChartData.datasets[0].alloc[i] = allocation[i];
    ciambellaChartData.datasets[0].inner[i] = inner[i];
    ciambellaChartData.datasets[0].outer[i] = outer[i];
    ciambellaChartData.datasets[0].impact[i] = impact[i];

    //set the data object spi[], if it exists, to the score[] value...
    try {
      ciambellaChartData.datasets[0].spi[i] = score[i];
    } catch (err) {
      ciambellaChartData.datasets[0].spi[i] = 0;
    }

    //set the data object total_score[], if it exists, to the total_score[] value...
    try {
      ciambellaChartData.datasets[0].total_score[i] = total_score[i];
    } catch (err) {
      ciambellaChartData.datasets[0].total_score[i] = 0;
    }

    //make tooltip labels and legend labels have classifaction id and topic name at front...
    if (
      ciambellaChartData.datasets[0].class_name === "GRIS" ||
      ciambellaChartData.datasets[0].class_name === "SDGS" ||
      ciambellaChartData.datasets[0].class_name === "MCS"
    ) {
      ciambellaChartData.labels[i] = topicNumber[i] + " - " + topicName[i];
    } else if (ciambellaChartData.datasets[0].class_name === "WSSI") {
      //WSSI is slightly different...

      //init  ciambellaChartData.labels[i] to be blank...
      ciambellaChartData.labels[i] = "";

      //set ciambellaChartData.labels[i] to be the topic number...
      for (let k = topicNumTemp.length - 1; k > 0; k--) {
        ciambellaChartData.labels[i] =
          ciambellaChartData.labels[i] + topicNumTemp[topicNumTemp.length - k];
      }

      //add the topic name...
      ciambellaChartData.labels[i] =
        ciambellaChartData.labels[i] + " - " + topicName[i];
    }

    i++;
  }

  //get entity_id from response...
  ciambellaChartData.datasets[0].entity_id = rows[0].entity_id;

  //get entity_name from response...
  ciambellaChartData.datasets[0].entity_name = rows[0].entity_name;

  //Check if alloc[] is empty and if so:
  // 1. give it a fixed value for each item.
  // 2. Fill the 'data' array in the chart data object so the chart can draw slices.
  // 3. Fill the 'alloc' array in the chart data object so the chart can calculate the
  //    angles for the lines and dots for SPi values.
  // 4. Also set the background color to a gradient for each slice.
  // else
  //    Fill the backgroundColour array in the data object with colours from the classification drop down
  //    this will need to get colours from a table in the v3 system.

  //is allocation[] Nan?

  let allocIsEmpty = true;
  for (let i = 0; i < rows.length; i++) {
    if (isNaN(allocation[i])) {
      allocIsEmpty = true;
    } else {
      allocIsEmpty = false;
      break;
    }
  }

  console.log("allocation array is empty: " + allocIsEmpty);

  let classColours = {
    GRIS: [
      "#D0D0D0",
      "#C0C0C0",
      "#B0B0B0",
      "#8FC5FE",
      "#72B3F6",
      "#55A2EE",
      "#3990E6",
      "#1C7FDE",
      "#006ED6",
      "#C0E6B5",
      "#A4E4B5",
      "#89D2A6",
      "#6DC097",
      "#52AF87",
      "#369D78",
      "#1B8B69",
      "#007A5A",
      "#FDA1A0",
      "#F69796",
      "#F08E8D",
      "#EA8583",
      "#E47C7A",
      "#DE7370",
      "#D76967",
      "#D1605E",
      "#CB5754",
      "#C54E4B",
      "#BF4541",
      "#B93C38",
      "#B2322F",
      "#AC2925",
      "#A6201C",
      "#A01712",
      "#9A0E09",
      "#940500",
      "#840300",
    ],
    SDGS: [
      "#E5233B",
      "#DCA63A",
      "#4C9E38",
      "#C51A2D",
      "#FF3A20",
      "#25BDE2",
      "#FBC30A",
      "#A21A42",
      "#FD6824",
      "#DD1367",
      "#FD9D25",
      "#C08A30",
      "#3F7E45",
      "#3F7E45",
      "#56C02A",
      "#00689D",
      "#1A486A",
    ],
    WSSI: [
      "#213261",
      "#293E78",
      "#30498E",
      "#3855A5",
      "#4061BB",
      "#476CD2",
      "#4F78E8",
      "#D28D09",
      "#D69009",
      "#DA9209",
      "#DE950A",
      "#E2980A",
      "#E69B0A",
      "#EB9D0A",
      "#EFA00A",
      "#F3A30A",
      "#F7A60B",
      "#FBA80B",
      "#4B7834",
      "#4F7F37",
      "#53863A",
      "#588C3D",
      "#5C9340",
      "#609A43",
      "#64A146",
      "#69A748",
      "#6DAE4B",
      "#71B54E",
      "#75BC51",
      "#79C254",
      "#7EC957",
      "#82D05A",
      "#86D75D",
      "#8ADD60",
      "#8FE463",
      "#93EB66",
      "#97F269",
      "#9BF86C",
      "#C21727",
      "#C61728",
      "#CA1829",
      "#CE1829",
      "#D2192A",
      "#D6192B",
      "#DA1A2C",
      "#DE1A2D",
      "#E31B2E",
      "#E71B2E",
      "#EB1C2F",
      "#EF1C30",
      "#F31D31",
      "#F71D32",
      "#FB1E32",
      "#EB6412",
      "#EE6512",
      "#F16612",
      "#F46813",
      "#F66913",
      "#F96A13",
      "#FC6B13",
    ],
    MCS: [
      "#D6E5BD",
      "#B3EA54",
      "#86AC44",
      "#ADCBE7",
      "#79B9F5",
      "#207ED6",
      "#E7B9B8",
      "#D5817F",
      "#C24542",
    ],
  };

  if (allocIsEmpty) {
    for (let i = 0; i < rows.length; i++) {
      //just a fixed amount for each element. Here I'm using 10, but it could be any positive number.
      ciambellaChartData.datasets[0].data[i] = 10;
      ciambellaChartData.datasets[0].alloc[i] = 10;
    }

    //also if allocIsEmpty then set the background color to a gradient...
    ciambellaChartData.datasets[0].backgroundColor = rainbowColours(
      rows.length
    ); //gradient;
  } else {
    //set backgroundColour array classColours object using ciambellaChartData.datasets[0].class_name as the key...
    ciambellaChartData.datasets[0].backgroundColor =
      classColours[ciambellaChartData.datasets[0].class_name];
    console.log(
      "auto detect array... ciambellaChartData.datasets[0].backgroundColor: " +
        ciambellaChartData.datasets[0].backgroundColor
    );
  }

  //console.log("agg: "+agg);
  //normalise values in spi if agg===true
  if (agg === true) {
    let highestValue = Math.max(...ciambellaChartData.datasets[0].spi);
    console.log("highest total_score: ");
    console.log(highestValue);
    //normalise the spi values...
    //doing this will make the spi values in the chart all be between 0 and 1 and you'll lose the total_score values for the label (which are much higher than 1, sometimes hundreds).
    for (let i = 0; i < rows.length; i++) {
      ciambellaChartData.datasets[0].spi[i] =
        ciambellaChartData.datasets[0].spi[i] / highestValue;
    }
  }

  //create spiScore field in data object (ciambellaChartData.datasets[0].spiScore) as an average of all the spi values...
  //NOTE: This is only for demonstration purposes. It is not the correct calculation. The final calculation would take inner, outer, impact, etc
  //into account. This is just a quick way to show the concept of a spiScore.
  //The final calculation would produce a spiScore that is over 1 if any slices have a spi of over 1. To show an unsustainable entity.
  /* 
  for (let i = 0; i < rows.length; i++) {
    ciambellaChartData.datasets[0].spiScore +=
      ciambellaChartData.datasets[0].spi[i];
  }
   */
  ciambellaChartData.datasets[0].spiScore = SPIScore;

  //ciambellaChartData.datasets[0].spiScore / rows.length;

  //drawCustomLegend();

  //draw table to help understand the data...
  //drawTable();

  //update the chart
  //chart.update();

  //paint();

  // loop thought ciambellaChartData and muliply each spi[] value by weight[]
  ciambellaChartData.datasets[0].spi = ciambellaChartData.datasets[0].spi.map(
    (value, i) => value * weight[i]
  );

  return ciambellaChartData;
}

//this function was rewritten by copilot based on mine.
//It is more compact and produces a better gradient.
function rainbowColours(total) {
  var pool = [];
  var maxPrimary = 235;
  var numberOfSpectralColours = 6;
  var stepAmount = (maxPrimary / (total - 1)) * numberOfSpectralColours;

  for (let i = 0; i < total; i++) {
    var r, g, b;
    if (i === 0) {
      // Set the RGB values directly for a pink color
      r = 255; // Maximum red
      g = 225; // Medium green
      b = 240; // High blue
    } else if (i < total / numberOfSpectralColours) {
      r = maxPrimary;
      g = Math.max(maxPrimary - stepAmount * i, 0);
      b = maxPrimary; //Math.max(maxPrimary - stepAmount * i, 0);
    } else if (i < (total / numberOfSpectralColours) * 2) {
      r = maxPrimary;
      g = stepAmount * (i - total / numberOfSpectralColours);
      b = 0;
    } else if (i < (total / numberOfSpectralColours) * 3) {
      r = Math.max(
        maxPrimary - stepAmount * (i - (total / numberOfSpectralColours) * 2),
        0
      );
      g = maxPrimary;
      b = Math.max(
        255 -
          maxPrimary +
          stepAmount * (i - (total / numberOfSpectralColours) * 2),
        0
      );
    } else if (i < (total / numberOfSpectralColours) * 4) {
      r = 0;
      g = Math.max(
        maxPrimary - stepAmount * (i - (total / numberOfSpectralColours) * 3),
        0
      );
      b = maxPrimary;
    } else if (i < (total / numberOfSpectralColours) * 5) {
      r = Math.max(stepAmount * (i - (total / numberOfSpectralColours) * 4), 0);
      g = 0;
      b = maxPrimary;
    } else {
      r = maxPrimary;
      g = Math.max(stepAmount * (i - (total / numberOfSpectralColours) * 5), 0);
      b = maxPrimary;
    }

    var hexColor = rgbToHex("rgb(" + r + "," + g + "," + b + ")");
    pool.push(hexColor);
  }

  return pool;
}

function rgbToHex(rgb) {
  var r = parseInt(rgb.substring(4, rgb.indexOf(",")));
  var g = parseInt(rgb.substring(rgb.indexOf(",") + 1, rgb.lastIndexOf(",")));
  var b = parseInt(rgb.substring(rgb.lastIndexOf(",") + 1, rgb.indexOf(")")));

  var hexR = r.toString(16).padStart(2, "0");
  var hexG = g.toString(16).padStart(2, "0");
  var hexB = b.toString(16).padStart(2, "0");

  return "#" + hexR + hexG + hexB;
}
