import React from "react";
//import { loadLegendData } from './loadLegendData';
import { loadCiambellaChartData } from "./ccjsv6";

/*********************************************************************************
 * Legend2 component
 */
function Legend({ APIData, sliceIsVisible, setSliceIsVisible, weight }) {
  //check if data is empty and return null if it is
  if (!APIData) {
    return null;
  }

  //var legendData=loadLegendData(APIData, sliceIsVisible);
  var legendData = loadCiambellaChartData(APIData, sliceIsVisible, weight);

  //check if legendData is undefined and return null if it is
  if (!legendData) {
    return null;
  }

  //console.log("Legend data: " + APIData);
  console.log("Legend sliceIsVisible: " + sliceIsVisible);
  console.log("Legend ciambellaChartData: ", legendData);

  var legendRowHeight;
  var legendSwatchHeight;
  var legendPaddingBottom;
  var legendVerticalAlign;

  let legendScreenSize = "desktop";
  //props.legendScreenSize="mobile";

  //if screen is smaller than 900px set legengScreenSize to "mobile"...
  //let width = window.innerHeight;

  //console.log("document.documentElement.clientWidth: " + document.documentElement.clientWidth);

  ////////////if (document.documentElement.clientWidth<900){legendScreenSize="mobile";}else {legendScreenSize="desktop";}

  //console.log("legendScreenSize: " + window.innerHeight);

  //size the swatch and legend item heights for large screens...
  if (legendScreenSize === "desktop") {
    legendRowHeight = 13;
    legendSwatchHeight = 13;
    legendPaddingBottom = 10;
    legendVerticalAlign = 0;
  }

  //size the swatch and legend item heights for small screens...
  if (legendScreenSize === "mobile") {
    legendRowHeight = 23;
    legendSwatchHeight = 40; //43; //30;
    legendPaddingBottom = 25; //15;
    legendVerticalAlign = 13; //14;
  }

  function toggleCustomLegendItem(index) {
    setSliceIsVisible(toggleSlice(sliceIsVisible, index));
  }

  function toggleSlice(sliceIsVisible, index) {
    return sliceIsVisible.map((value, i) => {
      if (i === index) {
        return !value;
      } else {
        return value;
      }
    });
  }

  const strike = {
    textDecoration: "line-through",
    border: "0px solid orange",
    paddingLeft: 0 + "px",
    fontFamily: "Lucida Console",
    fontSize: 14 + "px",
    color: "#000",
    fontWeight: "1000",
    verticalAlign: legendVerticalAlign + "px",
    lineHeight: 0 + "px",
    overflow: "hidden",
  };

  const noStrike = {
    textDecoration: "none",
    border: "0px solid orange",
    paddingLeft: 0 + "px",
    fontFamily: "Lucida Console",
    fontSize: 14 + "px",
    color: "#000",
    fontWeight: "1000",
    verticalAlign: legendVerticalAlign + "px",
    lineHeight: 0 + "px",
    overflow: "hidden",
  };

  return (
    <>
      <table
        style={{
          border: "0px solid red",
          paddingInlineStart: "0px",
          userSelect: "none",
        }}
      >
        <tbody>
          {/* Loop through and select slices */}

          {legendData.datasets &&
            legendData.datasets[0] &&
            legendData.datasets[0].data.map((item, i) => (
              <tr
                key={i}
                style={{
                  border: "0px solid green",
                  height: legendRowHeight + "px",
                  listStyleType: "none",
                  marginBottom: legendPaddingBottom + "px",
                }}
              >
                <td>
                  <button
                    onClick={(event) => toggleCustomLegendItem(i)}
                    style={{
                      border: "0px solid blue",
                      background: legendData.datasets[0].backgroundColor[i],
                      width: 43 + "px",
                      height: legendSwatchHeight + "px",

                      marginBottom: 0 + "px",
                    }}
                  />
                </td>
                <td>&nbsp;</td>
                <td>
                  <span
                    id={"label" + i}
                    onClick={(event) => toggleCustomLegendItem(i)}
                    style={
                      sliceIsVisible && sliceIsVisible[i] === true
                        ? noStrike
                        : strike
                    }
                  >
                    {legendData.labels && legendData.labels[i]}
                  </span>
                </td>
              </tr>
            ))}
        </tbody>
      </table>
    </>
  );
}

export default Legend;
