import React from "react";
import { loadCiambellaChartData } from "./ccjsv6";

/*********************************************************************************
 * Display Classification component
 */
function DisplayClassification({ APIData, sliceIsVisible, weight }) {
  //check if data is empty and return null if it is
  if (!APIData) {
    return null;
  }

  var ClassData = loadCiambellaChartData(APIData, sliceIsVisible, weight);

  //check if ClassData is undefined and return null if it is
  if (!ClassData) {
    return null;
  }

  return <>Classification -&nbsp;{ClassData.datasets[0].class_name}</>;
}

export default DisplayClassification;
