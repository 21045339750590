import React, { useRef, useEffect, useState } from "react";

//TEST APIs
//classifications: https://zylascope.com/api/thrive/class/index.html

function Search(props) {
  const [searchText, setSearchText] = useState("");
  return (
    <>
      <textarea
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        rows={5}
        style={{ width: "100%" }}
      />
      <button
        onClick={() => {
          console.log(searchText);
          //send the API data to the parent component...
          props.setAPIData(searchText);
          //make all sliceas visible by called resetSlices function from the parent component...
          props.resetSlices();
          //reset the weights to 1...
          props.resetWeights();
        }}
      >
        Search
      </button>
    </>
  );
}

export default Search;
