//import * as React from "react";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  styled,
  createTheme,
  ThemeProvider,
  useTheme,
} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { MenuItems } from "./MenuItems";
import EntitySPIandRank from "./EntitySPIandRank";
import Presets from "./Presets";
import "./styles.css";
import { bgcolor } from "@mui/system";
import $ from "jquery";
import { DinnerDining } from "@mui/icons-material";

import SearchForm from "./Components/SearchForm";

import Search from "./Search";
import CiambellaChart from "./CiambellaChart";
import Legend from "./Legend";
import THRIVEGrid from "./THRIVEGrid";
import DisplayClassification from "./DisplayClassification";
import axios from "axios";
import { serverDomain } from "./config";

import SnippetPopUp from "./SnippetPopUp";
import ProfileEditorPopUp from "./ProfileEditorPopUp";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://strive2thrive.earth/">
        Strive2Thrive.Earth
      </Link>{" "}
      {new Date().getFullYear()}
    </Typography>
  );
}

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const mdTheme = createTheme();
/* const mdTheme = createTheme({
  palette: {
    primary: {
      main: '#00f',
    },
    secondary: {
      main: '#0f0',
    },
  },
}); */

function DashboardContent() {
  const [open, setOpen] = React.useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
  };

  //state and click handler for SnippetPopUp...
  const [isPopupOpen, setPopupOpen] = React.useState(false);
  const togglePopup = () => {
    setPopupOpen(!isPopupOpen);
  };

  //state and click handler for ProfileEditorPopUp...
  const [profileOpen, setProfileOpen] = React.useState(false);

  //initialize the APIData state variable...
  const [APIData, setAPIData] = useState("");

  //initialize the sliceIsVisible boolean array state variable with 200 true elements...
  const [sliceIsVisible, setSliceIsVisible] = useState(Array(200).fill(true));

  //initialize the weight array state variable with 200 elements of value=1...
  const [weight, setWeight] = useState(Array(200).fill(1));

  //I put selectAllSlices state variable here in the parent qnd passed down to Grid and Ciambella Chart
  //And added to the dependency array of the useEffect hook in CiambellaChart.js to see if it would update
  //the Ciambella Chart when the selectAllSlices checkbox is toggled to prevent the bug where the inner text
  //of the Ciambella Chart does not update when the selectAllSlices checkbox is toggled. But it didn't fix it.
  //create state for selectAllSlices checkbox...
  const [selectAllSlices, setSelectAllSlices] = useState(true);

  function resetWeights() {
    setWeight(Array(200).fill(1));
  }

  function resetSlices() {
    setSliceIsVisible(Array(200).fill(true));
  }

  //initialize searchFormDate state variable. Holds the current search form selections...
  const [searchFormData, setSearchFormData] = useState({});

  //initialize the numberOfEntities state variable. This is used for displaying rank x of y, where y is the total NumberOfEntities in seaqrch result...
  const [numberOfEntities, setNumberOfEntities] = useState(0);

  //initialize the SPIScore state variable...
  const [SPIScore, setSPIScore] = useState(0);

  //initialize the presetOtpions array state variable...
  const [presetOptions, setPresetOptions] = useState([]);

  //initialize the snippetDataObject state variable.
  //This is used to store the chart data from ccjsv6.js that will be
  //passed to the SnippetPopUp component and written out as javascript text...
  const [snippetDataObject, setSnippetDataObject] = useState({});

  //log it's value when it changes, for testing...
  useEffect(() => {
    console.log("snippetDataObject changed.");
    console.log("*****snippetDataObject*********");
    console.log("snippetDataObject", snippetDataObject);
    console.log("*****snippetDataObject*********");
    console.log("snippetDataObject", JSON.stringify(snippetDataObject));
  }, [snippetDataObject]);

  /* $(function () {
    console.log("load event");
    //LegendBox(); //it runs everytime the menu icon is clicked! Why
  }); */

  // Mediaquery breakpoints
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.up("md"));
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: "36px",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Dashboard
            </Typography>
            {/* <IconButton color="inherit">
              <Badge badgeContent={4} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton> */}
            THRIVE Platform - V3.1
          </Toolbar>
        </AppBar>

        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <List>
            <MenuItems setProfileOpen={setProfileOpen} />
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              {/* API Data Search Form Test GW */}
              <Grid item xs={12}>
                <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                  <ProfileEditorPopUp
                    profileOpen={profileOpen}
                    setProfileOpen={setProfileOpen}
                  />
                  <SearchForm
                    setAPIData={setAPIData}
                    resetSlices={resetSlices}
                    resetWeights={resetWeights}
                    setSearchFormData={setSearchFormData}
                    setNumberOfEntities={setNumberOfEntities}
                    setSPIScore={setSPIScore}
                    setPresetOptions={setPresetOptions}
                  />
                </Paper>
              </Grid>

              {/* API Data Search Form Test */}

              {/* <Grid item xs={12}>
                <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                  <SearchForm setAPIData={setAPIData} />
                </Paper>
              </Grid> */}

              {/* API Data Search Form Test with textarea */}
              {/* 
              <Grid item xs={12}>
                <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                  <Search
                    setAPIData={setAPIData}
                    resetSlices={resetSlices}
                    resetWeights={resetWeights}
                  />
                </Paper>
              </Grid>
          */}

              {/* Entity Name, SPI Score and Rank */}
              <Grid item xs={12} md={12} lg={12}>
                <Paper
                  sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                  }}
                >
                  <EntitySPIandRank
                    APIData={APIData}
                    sliceIsVisible={sliceIsVisible}
                    weight={weight}
                    searchFormData={searchFormData}
                    numberOfEntities={numberOfEntities}
                    setSPIScore={setSPIScore}
                    SPIScore={SPIScore}
                  />
                </Paper>
              </Grid>

              <Grid item xs={12} md={8} lg={7}>
                <Paper
                  sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    maxHeight: "660px",
                    overflow: "hidden",
                  }}
                >
                  {/*Snippet PopUp*/}
                  <div>
                    {SPIScore >= 0 && (
                      <SnippetPopUp
                        open={isPopupOpen}
                        setOpen={togglePopup}
                        buttonText="Get HTML snippet"
                        htmlCode={snippetDataObject}
                        /* SPIScore={null} */
                      />
                    )}
                  </div>

                  {/* Ciambella Chart */}
                  <CiambellaChart
                    setSnippetDataObject={setSnippetDataObject}
                    APIData={APIData}
                    sliceIsVisible={sliceIsVisible}
                    weight={weight}
                    selectAllSlices={selectAllSlices}
                    SPIScore={SPIScore}
                  />
                </Paper>
              </Grid>

              <Grid item xs={12} md={4} lg={5}>
                <Paper
                  sx={{
                    p: 1,
                    display: "flex",
                    flexDirection: "column",
                    height: 660,
                  }}
                >
                  <Presets
                    APIData={APIData}
                    presetOptions={presetOptions}
                    searchFormData={searchFormData}
                    setSliceIsVisible={setSliceIsVisible}
                  />

                  {/* Classification */}
                  <Typography id="legendTitle" component="p" variant="h5">
                    <DisplayClassification
                      APIData={APIData}
                      sliceIsVisible={sliceIsVisible}
                      weight={weight}
                    />
                  </Typography>

                  {/* Legend */}
                  <Typography
                    id="legendBox"
                    component="h1"
                    variant="body2"
                    /* noWrap */
                    color="inherit"
                    sx={{ flexGrow: 1 }}
                    /* overflow="auto" */
                  >
                    <div style={{ overflowY: "auto", maxHeight: "560px" }}>
                      <Legend
                        APIData={APIData}
                        sliceIsVisible={sliceIsVisible}
                        setSliceIsVisible={setSliceIsVisible}
                        weight={weight}
                      />
                    </div>
                  </Typography>
                </Paper>
              </Grid>

              {/* THRIVE Grid Table */}
              <Grid item xs={12}>
                <Paper sx={{ p: 2, display: "flex", flexDirection: "column" }}>
                  <THRIVEGrid
                    APIData={APIData}
                    sliceIsVisible={sliceIsVisible}
                    setSliceIsVisible={setSliceIsVisible}
                    weight={weight}
                    setWeight={setWeight}
                    selectAllSlices={selectAllSlices}
                    setSelectAllSlices={setSelectAllSlices}
                  />
                </Paper>
              </Grid>
            </Grid>

            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default function Dashboard() {
  const history = useHistory();

  useEffect(() => {
    const isLoggedIn = localStorage.getItem("isLoggedIn");
    console.log("localStorage.isLoggedIn: ", isLoggedIn);
    if (isLoggedIn !== "true") {
      history.push("/login");
    }

    const token = localStorage.getItem("token");
    const checkUserLoggedIn = async () => {
      try {
        const response = await axios.post(
          serverDomain + "/php/isUserLoggedInAPI.php",
          {},
          { headers: { Authorization: `Bearer ${token}` } }
        );
        if (response.data.success === 0) {
          if (response.data.message === "Unauthorised") {
            console.log("API: User is not authorised.");
            history.push("/login?m=u");
          } else if (response.data.message === "User is not logged in.") {
            console.log("API: User is not logged in.");
            history.push("/login?m=n");
          }
        }
        //else user is logged in.
        if (response.data.success === 1) {
          console.log("API: User is logged in.");
        }
      } catch (error) {
        console.error("Error checking if user is logged in: ", error);
      }
    };

    // Run the function once at first render
    checkUserLoggedIn();

    // Then run it every 30 seconds
    const intervalId = setInterval(checkUserLoggedIn, 30000);

    // Clear the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, [history]);

  return <DashboardContent />;
}
