import * as React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Title from "./Title";
import { serverDomain } from "./config";
import axios from "axios";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import CircularProgress from "@mui/material/CircularProgress";

export default function Presets({
  APIData,
  presetOptions,
  searchFormData,
  setSliceIsVisible,
}) {
  //initialize the presetNameIndex state...
  const [presetNameIndex, setPresetNameIndex] = useState(0);

  //init the loading state...
  const [loading, setLoading] = useState(false);

  //make sure the index is zero when rendering list or when new presetOptions are passed in ...
  useEffect(() => {
    setPresetNameIndex(0);
  }, [presetOptions]);

  //send the preset name to the gridPreset.php API to get list of topics...
  const sendPresetName = async () => {
    //check if data is empty and return null if it is...
    if (!presetOptions || presetOptions.length === 0) {
      return null;
    }
    const presetAPIData = {
      dateTimeEndId: searchFormData.dateTimeEndId,
      dateTimeStartId: searchFormData.dateTimeStartId,
      classSelected: searchFormData.classId,
      level: searchFormData.levelId,
      feId: searchFormData.feId,
      wfId: searchFormData.wfId,
      entityId: searchFormData.entityId,
      name: presetOptions[presetNameIndex].name,
    };
    //log presetAPIData...
    console.log("presets, presetAPIData: ", presetAPIData);

    const url = new URL(serverDomain + "/php/gridPreset.php");
    appendParams(url, presetAPIData);
    console.log("Presets url: " + url.toString());

    //display spinner...
    setLoading(true);

    //API call...
    axios
      .get(url, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        //log
        console.log(
          "From sendPresetName() in Presets.js API:gridPreset.php...",
          res
        );

        //clear the spinner...
        setLoading(false);

        if (res.data.gridPreset1 === false) {
          console.log("No topics found for this preset... gridPreset1===false");
          //no presets found, set all slices to true...
          var slices = new Array(200).fill(true);
          setSliceIsVisible(slices);
          return;
        }

        for (const presetRow of res.data.gridPreset1) {
          console.log(presetRow);
          console.log(presetRow.class_path_id);
        }

        //find which topics from APIData are in gridPreset list and set the sliceIsVisible state...
        // Set all slices[] to false...
        var slices = new Array(200).fill(false);

        let index = 0;
        // Loop through each row in APIData...
        var objArray = JSON.parse(APIData); //convert JSON string to javascript object
        //var resObj = JSON.parse(res); //convert JSON string to javascript object
        for (const row of objArray.data) {
          console.log(row);
          console.log(row.class_path_id);

          // Remove pipes from class_path_id...
          const class_path_id_pipes_removed = row.class_path_id.replace(
            /\|/g,
            ""
          );
          console.log(
            "class_path_id_pipes_removed: ",
            class_path_id_pipes_removed
          );

          // Check if presetTopcs contains row.class_path_id...
          const found = res.data.gridPreset1.some(
            (topic) => topic.class_path_id === class_path_id_pipes_removed
          );
          if (found) {
            // Set sliceIsVisible[index] to true if APIData.data.class_path_id (row.class_path_id) exists in presetTopcis (res.class_path_id) --> (topic.class_path_id)...
            slices[index] = true;
          }
          index++;
        }

        console.log("index... ", index);

        //set sliceIsVisible state in Dashboard...
        setSliceIsVisible(slices);
        /* 
      const data = APIData.data;
      console.log('class_path_ids...');
      for (const row of data) {
        console.log(row.class_path_id);
      }
*/
      });
  };

  //make useEffect that responds to changes of PresetNameIndex and calls sendPresetName()
  useEffect(() => {
    sendPresetName();
  }, [presetNameIndex]);

  //check if data is empty and return null if it is...
  if (!APIData) {
    return null;
  }

  /* //check if data is empty and return null if it is...
  if (!presetOptions || presetOptions.length === 0) {
    return null;
  } */

  //get the token from local storage...
  const token = localStorage.getItem("token");

  //append the parameters to the URL
  //appends the key-value pairs from the params object as query parameters to the url object
  const appendParams = (url, params) => {
    Object.entries(params).forEach(([k, v]) => url.searchParams.append(k, v));
  };

  function onChangePreset(option) {
    // set presetName state to the index of the selected option...
    setPresetNameIndex(option);
    console.log(`Presets, Selected option: ${option}`);
    console.log("Preset name", presetOptions[option].name);
    //sendPresetName();
  }

  return (
    <>
      <Grid container justifyContent="space-between">
        <Grid item xs={12} md={8}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              border: "0px solid green",
            }}
          >
            <TextField
              id="presets-selector"
              select
              label="Select preset"
              value={presetNameIndex}
              style={{
                width: "100%",
                border: "0px solid red",
                marginRight: "10px",
              }}
              onChange={(event) => {
                onChangePreset(event.target.value);
              }}
            >
              {presetOptions.map((option, index) => (
                <MenuItem key={index} value={index}>
                  {option.name}
                </MenuItem>
              ))}
            </TextField>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CircularProgress size={24} />
              </div>
            ) : (
              ""
            )}
          </div>
        </Grid>
      </Grid>
    </>
  );
}
