import * as React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Title from "./Title";
import { loadCiambellaChartData } from "./ccjsv6";
import axios from "axios";
import { serverDomain } from "./config";
import { set } from "date-fns";
import CircularProgress from "@mui/material/CircularProgress";

export default function EntitySPIandRank({
  APIData,
  sliceIsVisible,
  weight,
  searchFormData,
  numberOfEntities,
  setSPIScore,
  SPIScore,
}) {
  //initialize the totalScore state...
  const [totalScore, setTotalScore] = useState(0);

  //initialize the rank state...
  const [rank, setRank] = useState(0);

  //init the loading state...
  const [loading, setLoading] = useState(false);

  //append the parameters to the URL
  //appends the key-value pairs from the params object as query parameters to the url object
  const appendParams = (url, params) => {
    Object.entries(params).forEach(([k, v]) => url.searchParams.append(k, v));
  };

  //send the form data to the rank_score API...
  const sendFormData = async () => {
    const url = new URL(serverDomain + "/php/rank_score.php");
    appendParams(url, searchFormData);
    console.log("rank_score url: " + url.toString());
    //console.log(token);

    //display spinner...
    setLoading(true);

    //API call...
    axios
      .get(url, { headers: { Authorization: `Bearer ${token}` } })
      .then((res) => {
        //headers
        //console.log("From sendFormData() in EntitySPIandRank.js API:rank_score.php");
        //console.log(token);
        //console.log("url: " + url.toString());
        console.log(
          "From sendFormData() in EntitySPIandRank.js API:rank_score.php...",
          res,
          "totalScore: ",
          res.data.totalScore
        );

        setTotalScore(res.data.totalScore); //set state in this component...
        setSPIScore(res.data.totalScore); //set state in parent component...
        setRank(res.data.rank);

        //console.log("API Data: ", res.data.data);
        //console.log("API Data success: ", res.data.success);
        //console.log("API Data to display: ", res.data);

        //clear the spinner...
        setLoading(false);

        /* 
        //check is the user is unauthorized...
        if (res.data.message === "Unauthorized") {
          setUnauthorized(true);
        } else {
          setUnauthorized(false);
        }
 */

        /* 
        //set the apiData state only if the data object exists and the success field==1...
        if (
          res.data &&
          res.data.data &&
          res.data.success === 1 &&
          res.data.data !== false
        ) {
          //.dashboardTreeData.length
          //setAPIData(entityData);
          console.log("API returned success: 1 ");
          setAPIData(JSON.stringify(res.data));
          //make all sliceas visible by called resetSlices function from the parent component...
          resetSlices();
          //reset the weights to 1...
          resetWeights();
        }

        //if (res.data.data == null || res.data.data == false) {
        else if (res.data.data == null || res.data.data == false) {
          //if data is null set APIData state to empty string...
          setAPIData("");
          console.log(
            "API returned res.data=false so API Data set to empty string"
          );
        }
 */
      });
  };

  //call sendFormData() when the component is mounted...
  useEffect(() => {
    sendFormData();
  }, [APIData, searchFormData]); // Empty dependency array means this effect will run once when the component is mounted

  //get the token from local storage...
  const token = localStorage.getItem("token");

  //check if data is empty and return null if it is...
  if (!APIData) {
    return null;
  }

  var entityData = loadCiambellaChartData(
    APIData,
    sliceIsVisible,
    weight,
    SPIScore
  );
  //check if entityData is undefined and return null if it is...
  if (!entityData) {
    return null;
  }

  console.log("-------------------------------------------------------------");
  console.log("Entity ciambellaChartData: ", entityData);

  //check if searchFormData is empty and return null if it is...
  if (!searchFormData) {
    return null;
  }

  console.log("searchFormData: ", searchFormData);

  return (
    <>
      {entityData.datasets[0].entity_name ? (
        <>
          <Grid container justifyContent="space-between">
            <Grid item xs={12} md={8} className="EntitySPIandRankPadding">
              <Title>Entity name</Title>
              <Typography component="p" variant="h4">
                {entityData.datasets[0].entity_id} -{" "}
                {entityData.datasets[0].entity_name}
              </Typography>
            </Grid>
            <Grid item xs={12} md={2} className="EntitySPIandRankPadding">
              <Title>Rank</Title>
              {loading ? (
                <CircularProgress /> // This is the spinner that will be shown while loading
              ) : (
                <Typography component="p" variant="h4">
                  {rank || rank === 0
                    ? rank + " of " + numberOfEntities
                    : "No data"}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} md={2} className="EntitySPIandRankPadding">
              <Title>SPI Score</Title>
              {loading ? (
                <CircularProgress /> // This is the spinner that will be shown while loading
              ) : (
                <Typography component="p" variant="h4">
                  {totalScore || totalScore === 0 ? totalScore : "No data"}
                </Typography>
              )}
            </Grid>
          </Grid>
        </>
      ) : null}
    </>
  );
}
