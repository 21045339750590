import * as React from "react";
import ListItem from "@mui/material/ListItem";
//import ListItemIcon from "@mui/material/ListItemIcon";
//import ListItemText from "@mui/material/ListItemText";
import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import ListSubheader from "@mui/material/ListSubheader";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import PeopleIcon from "@mui/icons-material/People";
import BarChartIcon from "@mui/icons-material/BarChart";
import LayersIcon from "@mui/icons-material/Layers";
import AssignmentIcon from "@mui/icons-material/Assignment";
import SettingsIcon from "@mui/icons-material/Settings";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { serverDomain } from "./config";
import { Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  customTooltip: {
    backgroundColor: "#1976d2",
    color: "white",
  },
});

export function MenuItems({ setProfileOpen }) {
  const history = useHistory();
  const classes = useStyles();

  const handleProfileClick = () => {
    console.log("Profile clicked");
    setProfileOpen(true);
  };

  const handleLogout = async () => {
    console.log("Logging out...");
    const token = localStorage.getItem("token");

    try {
      const response = await axios.post(
        serverDomain + "/php-login-registration-api/logUserOut.php",
        {},
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.data.success === 1) {
        history.push("/login?m=n"); // redirect to /login
      }
    } catch (error) {
      console.error("Error logging out: ", error);
    }
  };

  return (
    <div>
      <ListItemButton onClick={handleProfileClick}>
        <Tooltip title="Profile" classes={{ tooltip: classes.customTooltip }}>
          <ListItemIcon>
            <PeopleIcon />
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary="Profile" />
      </ListItemButton>

      <ListItemButton onClick={handleLogout}>
        <Tooltip title="Log out" classes={{ tooltip: classes.customTooltip }}>
          <ListItemIcon>
            <SettingsIcon />
          </ListItemIcon>
        </Tooltip>
        <ListItemText primary="Log out" />
      </ListItemButton>
    </div>
  );
}
