import React, { useRef, useEffect } from "react";
//import load data function from ciambella chart javascript file ccjsv6.js...
import { loadCiambellaChartData, initChart, paint, clearChart } from "./ccjsv6";

// A custom hook that returns the context of a canvas element
const useCanvas = (canvasRef) => {
  const [context, setContext] = React.useState(null);

  useEffect(() => {
    // Get the canvas element from the ref
    const canvas = canvasRef.current;
    // Get the 2D context of the canvas
    const ctx = canvas.getContext("2d");
    // Set the context state
    setContext(ctx);
  }, [canvasRef]);

  return context;
};

// A React component that renders a canvas and draws something
// on it using JavaScript
const CiambellaChart = ({
  setSnippetDataObject,
  APIData,
  sliceIsVisible,
  weight,
  selectAllSlices,
  SPIScore,
}) => {
  // Create a ref for the canvas element
  const canvasRef = useRef(null);
  // Get the context from the custom hook
  const ctx = useCanvas(canvasRef);

  useEffect(() => {
    // Check if the ctx is available
    if (ctx) {
      let data;
      //only draw the chart if props.APIData contains some data.
      if (APIData && APIData && APIData !== "" && !isNaN(SPIScore)) {
        data = loadCiambellaChartData(
          APIData,
          sliceIsVisible,
          weight,
          SPIScore
        );
        initChart(ctx, canvasRef);
        paint();
      } else {
        data = loadCiambellaChartData(
          APIData,
          sliceIsVisible,
          weight,
          SPIScore
        );
        initChart(ctx, canvasRef);
        clearChart();
      }
      setSnippetDataObject(data);
    }
  }, [
    ctx,
    APIData,
    sliceIsVisible,
    weight,
    selectAllSlices,
    SPIScore,
    setSnippetDataObject,
  ]);

  return (
    <div>
      <div
        style={{
          backgroundColor: "white",
          border: "0px solid #ddd",
          width: "100%",
          height: "100%",
          padding: "0px",
        }}
      >
        <canvas
          id="CiambellaChart"
          ref={canvasRef}
          width={500}
          height={500}
          style={{
            backgroundColor: "white",
            border: "0px solid #aaa",
            width: "100%",
            height: "100%",
            padding: "0px",
            boxSizing: "border-box",
          }}
        />
      </div>
    </div>
  );
};

export default CiambellaChart;
