import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { styled } from "@mui/system";
import { Button } from "@mui/material";
import CodeIcon from "@mui/icons-material/Code";
import { Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Snackbar } from "@mui/material";
import { BorderColor } from "@mui/icons-material";
import { platformDomain } from "./config";

// Styled component for rounded corners
const StyledDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: "15px", // Adjust the border radius to your preference
  },
}));

const useStyles = makeStyles({
  customTooltip: {
    backgroundColor: "#1976d2",
    color: "white",
  },
  snackbarCustom: {
    "& .MuiSnackbarContent-root": {
      backgroundColor: "white",
      color: "blue",
      border: "1px solid blue",
    },
  },
});

const SnippetPopUp = ({ buttonText, htmlCode }) => {
  // Check if htmlCode and htmlCode.datasets are defined
  if (htmlCode && Array.isArray(htmlCode.datasets)) {
    // Remove the specified keys from the object
    htmlCode.datasets.forEach((dataset) => {
      delete dataset.data; //data is not needed for ccjsv6.js but it is needed for the Chart.js
      delete dataset.inner;
      delete dataset.outer;
      delete dataset.impact;
      delete dataset.total_score; //I think this was a temporary field used in the adapter function: loadCiambellaChartData() and not needed in the snippet html code
    });
  }
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [toastOpen, setToastOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(snippetText);
    setToastOpen(true); // Show toast
  };

  const handleToastClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setToastOpen(false); // Close toast
  };

  const snippetText = `<!-- THRIVE Ciambella Chart snippet. Copyright © https://strive2thrive.earth -->
  <script src="${platformDomain}ccjsv6-snippet.js"></script>
<script>snippetData=${JSON.stringify(htmlCode)};</script>
<!-- Title begin -->
<div style="border: 1px solid #eef; text-align: center; font-family: arial;"><script>document.write(snippetData.datasets[0].class_name+" - "); document.write(snippetData.datasets[0].entity_id + " - "); document.write(snippetData.datasets[0].entity_name);</script></div>
<!-- Title end -->
<!-- Ciambella Chart begin -->
<div style="backgroundColor: #fff; border: 1px solid #eef; width: 100%; padding: 0px;">
  <canvas id="CiambellaChart" width="500" height="500" style="background-color: #fff; border: 1px solid #fef; width: 100%; padding: 0px; box-sizing: border-box" />
</div>
<!-- Ciambella Chart end -->
<!-- Ciambella Chart legend begin --->
<div id="ciambellaChartLegend" style="backgroundColor: #fff; border: 1px solid #eef; width: 100%; padding: 0px;"></div>
<!-- Ciambella Chart legend end -->
<script>
initCiambellaChart('CiambellaChart');paintCiambellaChart();paintCiambellaChartLegend('ciambellaChartLegend');
</script>
`;

  return (
    <div>
      {/* <button onClick={handleClickOpen}>{buttonText}</button> */}
      <Tooltip
        title="Get HTML Snippet"
        classes={{ tooltip: classes.customTooltip }}
        placement="bottom-start" // Adjust this value as needed
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <IconButton variant="contained" onClick={handleClickOpen}>
            <CodeIcon />
          </IconButton>
          <span style={{ marginLeft: "8px" }}>Get HTML Snippet</span>
        </div>
      </Tooltip>
      <StyledDialog
        open={open}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <Typography variant="h6">THRIVE Code Snippet</Typography>
          <Typography>
            Copy this code and paste it into your website to display the THRIVE
            Ciambella Chart for the selected entity.
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <Typography gutterBottom sx={{ whiteSpace: "pre-line" }}>
            {snippetText}
          </Typography>
        </DialogContent>
        <Button
          variant="contained"
          color="primary"
          onClick={handleCopyToClipboard}
        >
          Copy to Clipboard
        </Button>
        <Snackbar
          open={toastOpen}
          autoHideDuration={6000}
          onClose={handleToastClose}
          message="Code was copied"
          className={classes.snackbarCustom}
        />
      </StyledDialog>
    </div>
  );
};

export default SnippetPopUp;
