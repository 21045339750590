import React, { useState } from "react";
import { loadCiambellaChartData } from "./ccjsv6";

function THRIVEGrid({
  APIData,
  sliceIsVisible,
  setSliceIsVisible,
  weight,
  setWeight,
  selectAllSlices,
  setSelectAllSlices,
}) {
  //check if data is empty and return null if it is
  if (!APIData) {
    return null;
  }

  var legendData = loadCiambellaChartData(APIData, sliceIsVisible, weight);

  //check if legendData is undefined and return null if it is
  if (!legendData) {
    return null;
  }

  //set sliceIsVisible state variable to a new array where the element at index is toggled...
  function toggleGridItem(index) {
    setSliceIsVisible(toggleSlice(sliceIsVisible, index));
  }

  //return a new array where the element at index is toggled...
  function toggleSlice(sliceIsVisible, index) {
    return sliceIsVisible.map((value, i) => {
      if (i === index) {
        return !value;
      } else {
        return value;
      }
    });
  }

  //this function toggles the visibility of all rows/slices...
  //set sliceIsVisible state variable to a new array where all elements are toggled...
  function toggleAllSlices() {
    setSliceIsVisible(toggleAll(sliceIsVisible));
    //toggle setSelectAllSlices state variable...
    setSelectAllSlices(!selectAllSlices);
  }

  //return a new array where all elements are toggled...
  function toggleAll(sliceIsVisible) {
    return sliceIsVisible.map((value, i) => {
      return !value;
    });
  }

  //this function SHOWS ALL rows/slices or HIDES ALL rows/slices...
  function toggleShowHideAll() {
    if (selectAllSlices === true) {
      setSliceIsVisible(Array(200).fill(false));
      //toggle setSelectAllSlices state variable...
      setSelectAllSlices(!selectAllSlices);
    } else {
      setSliceIsVisible(Array(200).fill(true));
      //toggle setSelectAllSlices state variable...
      setSelectAllSlices(!selectAllSlices);
    }
  }

  const whiteRow = {
    border: "1px solid black",
    fontSize: "14px",
    backgroundColor: "#fff",
  };

  const greyRow = {
    border: "1px solid black",
    fontSize: "14px",
    backgroundColor: "#e0e0e0",
  };

  return (
    <table
      style={{
        border: "1px solid black",
        borderCollapse: "collapse",
        padding: "0px",
        userSelect: "none",
      }}
    >
      <thead>
        <tr
          style={{
            border: "1px solid black",
            width: "100%",
            height: "40px",
            fontSize: "14px",
            color: "#fff",
            background: "#1976d2",
          }}
        >
          <td
            style={{
              border: "1px solid black",
              textAlign: "center",
              padding: "5px",
            }}
          >
            <input
              type="checkbox"
              checked={selectAllSlices === true ? true : ""}
              onChange={() => {
                toggleShowHideAll();
              }}
            />
          </td>
          <td
            style={{
              border: "1px solid black",
              textAlign: "center",
              padding: "5px",
            }}
          >
            Weight
          </td>
          <td
            style={{
              border: "1px solid black",
              textAlign: "center",
              padding: "5px",
            }}
          >
            Topic
          </td>
          <td
            className="collapsible"
            style={{
              border: "1px solid black",
              textAlign: "center",
              padding: "5px",
            }}
          >
            Allocation
          </td>
          <td
            className="collapsible"
            style={{
              border: "1px solid black",
              textAlign: "center",
              padding: "5px",
            }}
          >
            Inner
          </td>
          <td
            className="collapsible"
            style={{
              border: "1px solid black",
              textAlign: "center",
              padding: "5px",
            }}
          >
            Outer
          </td>
          <td
            className="collapsible"
            style={{
              border: "1px solid black",
              textAlign: "center",
              padding: "5px",
            }}
          >
            Impact
          </td>
          <td
            style={{
              border: "1px solid black",
              textAlign: "center",
              padding: "5px",
            }}
          >
            SPi
          </td>
        </tr>
      </thead>
      <tbody>
        {legendData.datasets &&
          legendData.datasets[0] &&
          legendData.datasets[0].data.map((item, i) => (
            <tr
              key={i}
              style={
                sliceIsVisible && sliceIsVisible[i] === true
                  ? whiteRow
                  : greyRow
              }
            >
              {/* How can I change the visibility of the checkbox check state based on. try using checked={true or false}  */}
              <td
                style={{
                  border: "1px solid black",
                  textAlign: "center",
                  padding: "5px",
                }}
              >
                {/* TopicIsVisible[i].toString() */}
                <input
                  type="checkbox"
                  checked={
                    sliceIsVisible && sliceIsVisible[i] === true ? true : false
                  }
                  onChange={() => {
                    toggleGridItem(i);
                  }}
                />
                {/* <div style={{width: "10px;", border: "1px solid black", textAlign: "center"}} onClick={() => {toggleGridItem(i)}}>{TopicIsVisible[i]==true?"✓":" . "}</div> */}
              </td>
              <td
                style={{
                  border: "1px solid black",
                  textAlign: "center",
                  padding: "5px",
                }}
              >
                <input
                  type="text"
                  value={weight[i]}
                  onChange={(event) => {
                    const newValue = event.target.value;
                    if (/^-?\d*\.?\d*$/.test(newValue)) {
                      const newWeight = [...weight];
                      newWeight[i] = event.target.value;
                      setWeight(newWeight);
                    }
                  }}
                  style={{ width: "50px" }}
                />
              </td>
              <td
                style={{
                  border: "1px solid black",
                  textAlign: "left",
                  padding: "5px",
                }}
              >
                {legendData.labels[i]}
              </td>
              <td
                className="collapsible"
                style={{
                  border: "1px solid black",
                  textAlign: "right",
                  padding: "5px",
                }}
              >
                {legendData.datasets[0].alloc[i]}
              </td>
              <td
                className="collapsible"
                style={{
                  border: "1px solid black",
                  textAlign: "right",
                  padding: "5px",
                }}
              >
                {isNaN(legendData.datasets[0].inner[i])
                  ? 0
                  : legendData.datasets[0].inner[i]}
              </td>
              <td
                className="collapsible"
                style={{
                  border: "1px solid black",
                  textAlign: "right",
                  padding: "5px",
                }}
              >
                {isNaN(legendData.datasets[0].outer[i])
                  ? 0
                  : legendData.datasets[0].outer[i]}
              </td>
              <td
                className="collapsible"
                style={{
                  border: "1px solid black",
                  textAlign: "right",
                  padding: "5px",
                }}
              >
                {isNaN(legendData.datasets[0].impact[i])
                  ? 0
                  : legendData.datasets[0].impact[i]}
              </td>
              <td
                style={{
                  border: "1px solid black",
                  textAlign: "right",
                  padding: "5px",
                }}
              >
                {legendData.datasets[0].spi[i].toFixed(3)}
              </td>
            </tr>
          ))}
      </tbody>
    </table>
  );
}

export default THRIVEGrid;
